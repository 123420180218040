<div class="preview" [ngClass]="{ 'preview--wide': wide }">
  <div class="preview__thumbnail-container" *ngIf="!!preview.thumbnailSrc">
    <a (click)="onNavigateToPage($event, preview.slug)" class="preview__link" tabindex="-1" aria-hidden="true">
      <img
        [src]="preview.thumbnailSrc"
        [alt]="preview.thumbnailAlt"
        class="preview__thumbnail"
      />
    </a>
    <div class="preview__thumbnail__media" *ngIf="!minimal">
      <button
        class="preview__thumbnail__media__item youtube"
        aria-label="Youtube"
        *ngIf="!!preview.videoSlug"
        (click)="onVideoClick($event, preview.videoSlug)"
      ></button>
      <button
        class="preview__thumbnail__media__item gallery"
        aria-label="Galéria"
        *ngIf="!!preview.galleryId"
        (click)="onGalleryClick($event, preview.galleryId)"
      ></button>
    </div>
  </div>

    <a (click)="onNavigateToPage($event, preview.slug)" class="preview__link">
      <div class="preview__details" *ngIf="!minimal">
        <div class="preview__details__date">
          {{ preview.date | date: "yyyy.MM.dd":"HU-hu" }}
        </div>
        <div class="preview__details__category" *ngIf="!wide">{{ preview.category }}</div>
      </div>
      <div class="preview__title">{{ preview.title }}</div>
      <div class="preview__lead" [innerHTML]="bypassHtml(preview.lead || '')">
      </div>
    </a>
</div>
