<main class="home">
  <!-- Top main -->
  <div class="container">
    <ng-container *ngIf="!!homeData?.highlighted_message">
      <app-info [highlightedMessage]="homeData?.highlighted_message"></app-info>
    </ng-container>

    <div class="home__container">
      <div class="home__main">
        <div class="home__carosuel">
          <app-carousel
            [pages]="homeData?.highlighted_articles"
            [max-img-width]="maxImgWidthCarosuel"
          ></app-carousel>
        </div>

        <!-- Top sidebar components (Tablet view)  -->
        <div class="home__aside-to-main">
          <div class="home__mayor" *ngIf="!!sidebarData?.mayor">
            <app-mayor [mayor]="sidebarData?.mayor"></app-mayor>
          </div>

          <div class="home__calendar">
            <div class="title">Eseménynaptár</div>
            <app-calendar
              class="calendar-container"
              (dateClicked)="calendarClicked($event)"
            ></app-calendar>
          </div>

          <div class="home__banners">
            <app-banner
              class="home__banners__item"
              *ngIf="!!sidebarData?.banner[0]"
              [banner]="sidebarData?.banner[0]"
            ></app-banner>
            <app-banner
              class="home__banners__item"
              *ngIf="!!sidebarData?.banner[1]"
              [banner]="sidebarData?.banner[1]"
            ></app-banner>
          </div>
        </div>

        <div class="home__aside-to-main">
          <div class="home__actions">
            <a routerLink="/phonebook" class="button">Telefonkönyv</a>
            <a href="https://rakosmente.budapestkozut.hu/" class="button"
              >Interaktív térkép</a
            >
          </div>
        </div>

        <div class="home__administration">
          <section class="administration-container">
            <a
              [routerLink]="['/case']"
              class="administration__button administration__button--admin"
            >
              <div class="administration__button__text">ÜGYINTÉZÉS</div>
            </a>
            <a
              [routerLink]="['/static-pages/ugyintezes-elugy-tajek']"
              class="administration__button administration__button--e-admin"
            >
              <div class="administration__button__text">E-ÜGYINTÉZÉS</div>
            </a>
            <a
              [routerLink]="['/static-pages/idopontkeres-uegyfelfogadasra']"
              class="administration__button administration__button--appointment"
            >
              <div class="administration__button__text">IDŐPONT FOGLALÁS</div>
            </a>
          </section>
        </div>

        <div class="home__article-list">
          <section class="grid">
            <!--- TODO make grid componenet with ng-content (like slider) -->
            <div class="grid__title">
              <div class="title">Aktualitások</div>
            </div>
            <div class="grid__container">
              <div
                class="grid__item"
                *ngFor="let article of homeData?.other_articles"
              >
                <app-preview
                  [preview]="
                    mainService.transformArticleToPreview(
                      article,
                      articleTypes.OTHER
                    )
                  "
                ></app-preview>
              </div>
            </div>
          </section>
        </div>

        <div class="home__article-list home__article-list--mobile">
          <section class="grid">
            <div class="grid__title">
              <div class="title">További hírek</div>
            </div>
            <div class="grid__container">
              <div
                class="grid__item"
                *ngFor="let article of homeData?.actual_articles"
              >
                <app-preview
                  [preview]="mainService.transformArticleToPreview(article)"
                ></app-preview>
              </div>
            </div>
          </section>
        </div>
      </div>

      <!-- Top sidebar -->
      <aside class="home__aside">
        <div class="home__mayor" *ngIf="!!sidebarData?.mayor">
          <app-mayor [mayor]="sidebarData?.mayor"></app-mayor>
        </div>

        <div class="home__banners">
          <app-banner
            class="home__banners__item"
            *ngIf="!!sidebarData?.banner[0]"
            [banner]="sidebarData?.banner[0]"
          ></app-banner>
          <app-banner
            class="home__banners__item"
            *ngIf="!!sidebarData?.banner[1]"
            [banner]="sidebarData?.banner[1]"
          ></app-banner>
        </div>

        <div class="home__calendar" id="sajt">
          <div class="title">Eseménynaptár</div>
          <app-calendar
            class="calendar-container"
            (dateClicked)="calendarClicked($event)"
          ></app-calendar>
        </div>

        <div class="home__actions">
          <a routerLink="/phonebook" class="button">Telefonkönyv</a>
          <a href="https://rakosmente.budapestkozut.hu/" class="button"
            >Interaktív térkép</a
          >
        </div>

        <div class="home__phone">
          <app-phone></app-phone>
        </div>

        <div class="home__facebook">
          <div
            class="fb-page"
            data-href="https://www.facebook.com/rakosmenteonkormanyzata/"
            data-tabs="timeline"
            data-width="260"
            data-height="342"
            data-small-header="false"
            data-adapt-container-width="true"
            data-hide-cover="false"
            data-show-facepile="true"
          >
            <blockquote
              cite="https://www.facebook.com/rakosmenteonkormanyzata/"
              class="fb-xfbml-parse-ignore"
            >
              <a href="https://www.facebook.com/rakosmenteonkormanyzata/"
                >Rákosmente Önkormányzata</a
              >
            </blockquote>
          </div>
        </div>

        <div class="home__newsletter">
          <app-newsletter></app-newsletter>
        </div>
      </aside>
    </div>
  </div>

  <!-- Middle slider (without sidebar) -->
  <div class="home__slider home__slider--actual">
    <div class="container">
      <section class="grid">
        <div class="grid__title">
          <div class="title">További hírek</div>
        </div>
        <div>
          <app-slider [options]="sliderActualOptions">
            <ng-container *ngFor="let article of infiniteSliderHomeData">
              <app-preview
                [preview]="mainService.transformArticleToPreview(article)"
              ></app-preview>
            </ng-container>
          </app-slider>
        </div>
      </section>
    </div>
  </div>

  <!-- Bottom main -->
  <div class="container">
    <div class="home__container">
      <div class="home__main">
        <div class="home__article-list">
          <section class="grid">
            <div class="grid__title">
              <div class="title">Információk</div>
            </div>
            <div class="grid__container">
              <div
                class="grid__item"
                *ngFor="let article of homeData?.information_articles"
              >
                <app-preview
                  [preview]="mainService.transformArticleToPreview(article)"
                ></app-preview>
              </div>
            </div>
          </section>
        </div>

        <!-- Bottom sidebar components (Tablet view)  -->
        <div class="home__aside-to-main home__aside-to-main--reverse">
          <div class="home__phone">
            <app-phone></app-phone>
          </div>

          <div class="home__newsletter">
            <app-newsletter></app-newsletter>
          </div>
        </div>

        <div class="home__aside-to-main">
          <div class="home__banners">
            <app-banner
              class="home__banners__item"
              *ngIf="!!sidebarData?.banner[2]"
              [banner]="sidebarData?.banner[2]"
            ></app-banner>
            <app-banner
              class="home__banners__item"
              *ngIf="!!sidebarData?.banner[3]"
              [banner]="sidebarData?.banner[3]"
            ></app-banner>
          </div>
        </div>
      </div>

      <!-- Bottom sidebar -->
      <aside class="home__aside">
        <div class="home__banners">
          <app-banner
            class="home__banners__item"
            *ngIf="!!sidebarData?.banner[2]"
            [banner]="sidebarData?.banner[2]"
          ></app-banner>
          <app-banner
            class="home__banners__item"
            *ngIf="!!sidebarData?.banner[3]"
            [banner]="sidebarData?.banner[3]"
          ></app-banner>
        </div>
      </aside>
    </div>
  </div>

  <!-- Bottom slider (without sidebar) -->
  <div class="home__slider home__slider--bottom" *ngIf="!!homeData?.logo">
    <div class="container">
      <app-slider [options]="sliderLogoOptions">
        <ng-container *ngFor="let logo of homeData?.logo">
          <div class="logo">
            <a [href]="logo.url" target="_blank">
              <img
                [src]="mainService.getSmallestImageUrl(logo.image)"
                class="logo__img"
                [alt]="logo.image.alternativeText"
              />
            </a>
          </div>
        </ng-container>
      </app-slider>
    </div>
  </div>
</main>
