<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<app-main>
  <ng-container>
    <div class="title narrow">Kérelem hátrányos helyzetű gyermek intézményi étkezésének elviteléhez</div>
    <div class="title--sub--sub">
      <strong>Hátrányos és halmozottan hátrányos helyzetű iskolás gyermek</strong> (rendszeres gyermekvédelmi kedvezményben részesülő) betegsége vagy indokolt távolléte esetén a szülő/gondviselő kérheti a megrendelt <strong>iskolai étkezés elvitelét a bejelentésben</strong> megjelölt időponttól.<br><br>
      A bejelentés a lemondás szabályai szerint történik, azaz a tárgynap 9 óráig leadott igény/lemondás a tárgynapot követő naptól lehetséges. Az étkezés elvitelének végét is jelezni kell.<br><br>
      <strong>Minden mező kitöltése kötelező!</strong>
    </div>
    <div class="report-form">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" id="report-form">
        <label>
          <span>Gyermek neve <span class="required">*</span></span>
          <input
            type="text"
            name="kid_full_name"
            id="kid_full_name"
            formControlName="kid_full_name"
            required
            [ngClass]="{ error: isInvalid('kid_full_name') }"
          />
          <div class="error-text" *ngIf="isInvalid('kid_full_name')">
            A mező kitöltése kötelező
          </div>
        </label>

        <label class="select-container">
          <span>Iskola neve <span class="required">*</span></span>
          <select
            name="school_name"
            id="school_name"
            formControlName="school_name"
          >
            <option
              *ngFor="let schoolName of
                  [
                    'Balassi',
                    'Czimra',
                    'Diadal',
                    'Gregor',
                    'Gyurkovics',
                    'Kossuth',
                    'Kőrösi',
                    'Szabadság sugárút',
                    'Újlak',
                    'Zrínyi'
                  ]"
              [ngValue]="schoolName">
              {{ schoolName }}
            </option>
          </select>
        </label>

        <label>
          <span>Gyermek osztálya <span class="required">*</span></span>
          <input
            type="text"
            name="class_name"
            id="class_name"
            formControlName="class_name"
            required
            [ngClass]="{ error: isInvalid('class_name') }"
          />
          <div class="error-text" *ngIf="isInvalid('class_name')">
            A mező kitöltése kötelező
          </div>
        </label>

        <label class="half-width">
          <span>Étkezés elvitelének kezdete <span class="required">*</span></span>
          <mat-form-field appearance="fill">
            <mat-label style="display: flex">Válasszon dátumot</mat-label>
            <input
              matInput
              [matDatepicker]="cateringStartDateDatePicker"
              [min]="cateringStartDateMin"
              name="catering_start_date"
              id="catering_start_date"
              formControlName="catering_start_date"
              [ngClass]="{ error: isInvalid('catering_start_date') }"
              (click)="cateringStartDateDatePicker.open()"
              [readonly]="true"
              #ref
              (dateChange)="cateringStartDateChanged(ref.value)"
            >
            <mat-datepicker #cateringStartDateDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
        </label>

        <label class="half-width">
          <span>Étkezés elvitelének vége <span class="required">*</span></span>
          <mat-form-field appearance="fill">
            <mat-label style="display: flex">Válasszon dátumot</mat-label>
            <input
              matInput
              [matDatepicker]="cateringEndDateDatePicker"
              [min]="cateringEndDateMin"
              name="catering_end_date"
              id="catering_end_date"
              formControlName="catering_end_date"
              [ngClass]="{ error: isInvalid('catering_end_date') }"
              (click)="cateringEndDateDatePicker.open()"
              [readonly]="true"
              [disabled]="disableCateringEndDate"
            >
            <mat-datepicker #cateringEndDateDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
          <div class="error-text" *ngIf="isInvalid('catering_end_date')">
            A mező kitöltése kötelező
          </div>
          <div class="error-text" *ngIf="invalidCateringEndDate">
            Hibás dátum! Az elvitel vége nem lehet az elvitel kezdete előtt.
          </div>
        </label>

        <label class="select-container">
          <span>Étkeztetés formája <span class="required">*</span></span>
          <select
            name="catering_type"
            id="catering_type"
            formControlName="catering_type"
          >
            <option
              *ngFor="let cateringType of
                  [
                    'Csak ebéd',
                    'Háromszori étkezés'
                  ]"
              [ngValue]="cateringType">
              {{ cateringType }}
            </option>
          </select>
        </label>

        <div class="title--sub--sub__alert">
          Felhívom a figyelmét, hogy a hátrányos helyzetű gyermek hozzátartozója az intézmény konyhájáról 11:30 – 12:00 között viheti el az adott napi ételt. Más időpontban a konyha nem tudja a kiszolgálást biztosítani!
        </div>

        <div class="report-form__captcha">
          <app-recaptcha
            [key]="siteKey"
            formControlName="recaptcha"
          ></app-recaptcha>
        </div>

        <div class="button-container">
          <button type="submit" form="report-form" [disabled]="!form.valid">
            Elküldés
          </button>
          <button type="button" (click)="resetForm()">Mégse</button>
        </div>
      </form>
    </div>
  </ng-container>
  <ng-container aside>
    <app-sidebar-article></app-sidebar-article>
  </ng-container>
</app-main>
