import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ContentTypes } from 'src/app/models/content-types';
import { MainService } from 'src/app/services/main.service';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ContentComponent implements OnInit {
  @Input() contents: ContentComponent[];

  maxImgWidth: number;

  contentType = ContentTypes;

  text: SafeHtml;

  constructor(public sanitizer: DomSanitizer,
    public mainService: MainService,
    private responsiveService: ResponsiveService) {}

  ngOnInit(): void {
    this.maxImgWidth =
    this.responsiveService.containerWidth < 672
      ? this.responsiveService.containerWidth
      : 672;
  }

  bypassHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html + '');
  }
  
  bypassResourceUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url + '');
  }

  getYoutubeEmbedUrlFromUrl(url: string) {
    let ytId = '';
    const urlTransformed = url
      .replace(/(>|<)/gi, '')
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

    if (urlTransformed[2] !== undefined) {
      ytId = urlTransformed[2].split(/[^0-9a-z_\-]/i)[0];
    } else {
      ytId = urlTransformed[0];
    }

    return this.bypassResourceUrl(
      'https://www.youtube-nocookie.com/embed/' + ytId
    );
  }
}
