import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { GalleryDialogComponent } from 'src/app/components/dialogs/gallery-dialog/gallery-dialog.component';
import { Article } from 'src/app/models/article';
import { Gallery } from 'src/app/models/gallery';
import { SliderOptions, SliderTheme } from 'src/app/models/slider-options';
import { DialogService } from 'src/app/services/dialog.service';
import { MainService } from 'src/app/services/main.service';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-article-show',
  templateUrl: './article-show.component.html',
  styleUrls: ['./article-show.component.scss'],
})
export class ArticleShowComponent implements OnInit {
  document: Article;
  gallery: Gallery;
  sliderOptions: SliderOptions;
  maxImgWidth: number;


  constructor(
    public mainService: MainService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private responsiveService: ResponsiveService
  ) {}

  ngOnInit(): void {
    this.maxImgWidth =
      this.responsiveService.containerWidth < 672
        ? this.responsiveService.containerWidth
        : 672;

    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) =>
          this.mainService.getArticlePage('/' + params.get('slug'))
        )
      )
      .subscribe((data: Article) => {
        this.document = data;
        if (!!this.document.gallery_id) {
          this.mainService
            .getGallery('/' + data.gallery_id)
            .subscribe((galleryData) => {
              this.gallery = galleryData;
              this.sliderOptions = new SliderOptions(
                this.gallery.image.length,
                { xs: 1, sm: 4 },
                true,
                SliderTheme.PRIMARY
              );
            });
        }
      });
  }
  openGallery(gallery: Gallery) {
    this.dialogService.openModalWithParams(GalleryDialogComponent, gallery);
  }
}
