<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<app-main>
  <ng-container>
    <div class="title narrow">{{formTitle}}</div>
    <div *ngIf="subTitle" class="title--sub--sub" [innerHTML]="subTitle"></div>
    <div class="report-form">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" id="report-form">
        <div class="report-form__errors" *ngIf="!form.valid && !form.pristine">
          <ul class="report-form__error-list">
            <ng-container *ngFor="let input of validationKeys | keyvalue">
              <li
                class="report-form__error"
                *ngIf="
                  form.get(input.key).errors?.required &&
                  form.get(input.key).touched
                "
              >
                {{ input.value }} mező kitöltése kötelező.
              </li>

              <li
                class="report-form__error"
                *ngIf="
                  form.get(input.key).errors?.email &&
                  form.get(input.key).touched
                "
              >
                {{ input.value }} nem megfelelő.
              </li>
              <li
                class="report-form__error"
                *ngIf="
                  form.get(input.key).errors?.fileSize &&
                  form.get(input.key).touched
                "
              >
                A fájl mérete nem lehet nagyobb mint
                {{ maximumFileSizeInBytes / 1000000 }}Mb
              </li>
              <li
                class="report-form__error"
                *ngIf="
                  form.get(input.key).errors?.fileType &&
                  form.get(input.key).touched
                "
              >
                Nem megfelelő fájl formátum (.doc, .docx, .tif, .png, .jpg,
                .zip).
              </li>
            </ng-container>
          </ul>
        </div>

        <label class="half-width">
          <span>Bejelentő vezetékneve <span *ngIf="isRequired('last_name')" class="required">*</span></span>
          <input
            type="text"
            name="last_name"
            id="last_name"
            formControlName="last_name"
            [ngClass]="{ error: isInvalid('last_name') }"
          />
          <div class="error-text" *ngIf="isInvalid('last_name') && isRequired('last_name')">
            A mező kitöltése kötelező
          </div>
        </label>

        <label class="half-width">
          <span>Bejelentő keresztneve <span *ngIf="isRequired('first_name')" class="required">*</span></span>
          <input
            type="text"
            name="first_name"
            id="first_name"
            formControlName="first_name"
            [ngClass]="{ error: isInvalid('first_name') }"
          />
          <div class="error-text" *ngIf="isInvalid('first_name') && isRequired('first_name')">
            A mező kitöltése kötelező
          </div>
        </label>

        <div class="title--sub">Bejelentő cím adatai</div>

        <label class="half-width">
          <span>Irányítószám / HRSZ <span *ngIf="isRequired('zip_code')" class="required">*</span></span>
          <input
            type="text"
            name="zip_code"
            id="zip_code"
            formControlName="zip_code"
            [ngClass]="{ error: isInvalid('zip_code') }"
          />
          <div class="error-text" *ngIf="isInvalid('zip_code')">
            A mező kitöltése kötelező
          </div>
        </label>

        <label class="half-width">
          <span>Város <span *ngIf="isRequired('city')" class="required">*</span></span>
          <input
            type="text"
            name="city"
            id="city"
            formControlName="city"
            [ngClass]="{ error: isInvalid('city') }"
          />
          <div class="error-text" *ngIf="isInvalid('city')">
            A mező kitöltése kötelező
          </div>
        </label>

        <label>
          <span
          >Utcanév, házszám, emelet, ajtószám
            <span *ngIf="isRequired('street')" class="required">*</span></span
          >
          <input
            type="text"
            name="street"
            id="street"
            formControlName="street"
            [ngClass]="{ error: isInvalid('street') }"
          />
          <div class="error-text" *ngIf="isInvalid('street')">
            A mező kitöltése kötelező
          </div>
        </label>

        <label class="half-width">
          <span>Bejelentő telefonszáma <span *ngIf="isRequired('phone_number')" class="required">*</span></span>
          <input
            type="text"
            name="phone_number"
            id="phone_number"
            formControlName="phone_number"
            [ngClass]="{ error: isInvalid('phone_number') }"
          />
          <div class="error-text" *ngIf="isInvalid('phone_number')">
            A mező kitöltése kötelező
          </div>
        </label>

        <label class="half-width">
          <span>Bejelentő e-mail címe <span *ngIf="isRequired('email')" class="required">*</span></span>
          <input
            type="email"
            name="email"
            id="email"
            formControlName="email"
            [ngClass]="{ error: isInvalid('email') }"
          />
          <div
            class="error-text"
            *ngIf="isInvalid('email') && form.get('email').errors?.required"
          >
            A mező kitöltése kötelező
          </div>
          <div
            class="error-text"
            *ngIf="isInvalid('email') && form.get('email').errors?.email"
          >
            Nem megfelelő email formátum
          </div>
        </label>

        <div class="title--sub">Bejelentés adatai</div>
        <label>
          <span>Kapcsolódó helyszín címe <span class="required">*</span></span>
          <input
            type="text"
            name="address"
            id="address"
            formControlName="address"
            required
            [ngClass]="{ error: isInvalid('address') }"
          />
          <div class="error-text" *ngIf="isInvalid('address')">
            A mező kitöltése kötelező
          </div>
        </label>

        <label>
          <span>Kapcsolódó helyszín helyrajzi száma</span>
          <input
            type="text"
            name="parcel_number"
            formControlName="parcel_number"
          />
        </label>

        <label>
          <span>Leírás <span class="required">*</span></span>
          <textarea
            name="description"
            id="description"
            formControlName="description"
            cols="30"
            rows="10"
            required
            [ngClass]="{ error: isInvalid('description') }"
          ></textarea>
          <div class="error-text" *ngIf="isInvalid('description')">
            A mező kitöltése kötelező
          </div>
        </label>

        <label class="file-upload">
          <span class="file-upload__info">
            Csatolható fájlok formátuma: doc, docx, tif, png, jpg, zip. Összesen
            egy fájl csatolható, melynek maximális mérete: {{ maximumFileSizeInBytes / 1000000 }} MB.
          </span>
          <span class="file-upload__choose">Fájl csatolása</span>
          <input
            type="file"
            name="file"
            id="file"
            formControlName="file"
            accept=".doc, .docx, .tif, .png, .jpg, .zip"
            (change)="uploadFile($event.target.files[0])"
          />
        </label>
        <div class="file-upload__chosen-file" *ngIf="!!uploadedFile">
          <span class="file-upload__chosen-file__name">{{
            uploadedFile.name
            }}</span>
          <button
            class="file-upload__chosen-file__delete"
            type="button"
            (click)="resetFile()"
          >
            Melléklet törlése
          </button>
        </div>
        <div
          class="file-error"
          *ngIf="isInvalid('file') && form.get('file').errors?.fileSize"
        >
          A fájl mérete nem lehet nagyobb mint
          {{ maximumFileSizeInBytes / 1000000 }}Mb
        </div>
        <div
          class="file-error"
          *ngIf="isInvalid('file') && form.get('file').errors?.fileType"
        >
          Nem megfelelő fájl formátum (.doc, .docx, .tif, .png, .jpg, .zip).
        </div>
        <div class="report-form__captcha">
          <app-recaptcha
            [key]="siteKey"
            formControlName="recaptcha"
          ></app-recaptcha>
        </div>

        <div class="button-container">
          <button type="submit" form="report-form" [disabled]="!form.valid">
            Elküldés
          </button>
          <button type="button" (click)="resetForm()">Mégse</button>
        </div>
      </form>
    </div>
  </ng-container>
  <ng-container aside>
    <app-sidebar-article></app-sidebar-article>
  </ng-container>
</app-main>
