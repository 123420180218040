<div class="content">
  <ng-container *ngFor="let content of contents">
    <ng-container [ngSwitch]="content?.__component">
      <section
        *ngSwitchCase="contentType.TEXT"
        [innerHTML]="content.text"
      ></section>

      <section *ngSwitchCase="contentType.IMAGE">
        <div class="content__image">
          <img
            [src]="mainService.getSmallestImageUrl(content?.image, maxImgWidth)"
            [alt]="content?.image.alternativeText"
          />
        </div>
      </section>

      <section *ngSwitchCase="contentType.VIDEO">
        <div
          class="content__embed embed embed--yt"
          *ngIf="!!content.video.youtube_link"
        >
          <iframe
            width="560"
            height="315"
            [src]="getYoutubeEmbedUrlFromUrl(content.video.youtube_link)"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="content__video" *ngIf="!!content.video_file">
          <video [src]="content?.video_file.url"></video>
        </div>
      </section>

      <section *ngSwitchCase="contentType.FILE">
        <div class="content__file">
          <a [href]="content?.file.url" target="_blank">
            {{ content?.file.name }}
          </a>
        </div>
      </section>

      <section *ngSwitchCase="contentType.STATIC">
        <p>
          <a [routerLink]="content?.slug">{{content?.title}}</a>
        </p>
      </section>
    </ng-container>
  </ng-container>
</div>
