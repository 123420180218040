import { Component, Input, OnInit } from '@angular/core';
import { Mayor } from 'src/app/models/mayor';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-mayor',
  templateUrl: './mayor.component.html',
  styleUrls: ['./mayor.component.scss'],
})
export class MayorComponent implements OnInit {
  @Input() mayor: Mayor;

  constructor(public mainService: MainService) {}

  ngOnInit(): void {}
}
