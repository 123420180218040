import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Pagination } from 'src/app/models/pagination';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss'],
})
export class PagerComponent implements OnInit, OnChanges {
  @Input() pager: Pagination;
  @Output() toPage = new EventEmitter();

  perPage = 8;
  dynamicPageCountLimit = 6; // -2 from actual page number, min. value:  3 
  pagerPages = [];

  constructor(private router: ActivatedRoute, private route: Router) {}

  ngOnInit(): void {}

  ngOnChanges() {
    if(!!this.pager) {
      this.perPage = this.pager.perPage;
      this.getPagingPages();
    }
  }

  navigateToPage(pagenum: number) {
    this.toPage.emit('' + pagenum);
  }

  getPagingPages() {
    // generates the pages between the first and the last page. The middle part of the pager:  1 [ ... 3 4 5 6 ... ] 100
    let pages = [];
    const current = this.pager.currentPage; // the active page
    const last = this.pager.lastPage; // all pages
    const limit = this.dynamicPageCountLimit; // max number of pages can be shown
    let pageCount = limit < last - 2 ? limit : last - 2; // how many pages we can choose from ( -2 becouse the first and the last page already there (static))
    const shift = (pageCount - 1) % 2; // - 1 because skipping the selected page in the middle
    let rangeBefore = ~~(pageCount / 2); // number of pages shown before the current page
    rangeBefore -= shift; // shift here (if there is no exact middle) to prefer forward paging exp: "... 3 4 [5] 6 ..."  -->  "... 4 [5] 6 7 ..."
    let from =
      last - current < pageCount ? last - pageCount : current - rangeBefore; // where to start (keep starting page on maximum value or around the current page)
    from = from <= 2 ? 2 : from; // keep starting page on minimum value
    for (let i = 0; i < pageCount; i++) {
      const page = from + i;
      if ((i === 0 && page > 2) || (i === pageCount - 1 && page < last - 1)) {
        // if can't start from the first or finish at the last page
        pages.push('...');
      } else {
        pages.push(page);
      }
    }
    this.pagerPages = pages;
  }
}
