import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Article } from 'src/app/models/article';
import { ArticleTypes } from 'src/app/models/article-types';
import { Home } from 'src/app/models/home';
import { Sidebar } from 'src/app/models/sidebar';
import { SliderOptions, SliderTheme } from 'src/app/models/slider-options';
import { MainService } from 'src/app/services/main.service';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  homeData: Home;
  sidebarData: Sidebar;
  sliderActualOptions: SliderOptions;
  sliderLogoOptions: SliderOptions;
  articleTypes = ArticleTypes;
  screenType: string;
  maxImgWidthCarosuel: number;
  infiniteSliderHomeData: Article[] = [];

  constructor(
    public mainService: MainService,
    private responsiveService: ResponsiveService
  ) {}

  ngOnInit(): void {
    this.maxImgWidthCarosuel =
      this.responsiveService.containerWidth < 672
        ? this.responsiveService.containerWidth
        : 672;

    this.mainService.getHome().subscribe((data: Home) => {
      this.homeData = data;
      this.sliderActualOptions = new SliderOptions(
        data.actual_articles.length,
        { xs: 3 },
        false,
        null,
        true,
        true
      );
      this.sliderLogoOptions = new SliderOptions(
        this.homeData.logo.length,
        { xs: 1, sm: 4, md: 5, lg: 6 },
        true
      );
      if (this.homeData?.actual_articles) {
        this.infiniteSliderHomeData = [
          ...this.homeData.actual_articles,
          ...this.homeData.actual_articles,
          ...this.homeData.actual_articles,
        ];
      }
    });
    this.mainService.getSidebar().subscribe((data: Sidebar) => {
      this.sidebarData = data;
    });
  }

  calendarClicked(ev) {
    this.mainService.navigateToEventPage();
  }
}
