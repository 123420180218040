export class SliderOptions {
  itemCount: number;
  itemInView: { xs: number; sm?: number; md?: number; lg?: number };
  isCentered: boolean;
  theme: SliderTheme;
  isInfinite: boolean;
  isAuto: boolean;

  constructor(
    itemCount: number,
    itemInView: { xs: number; sm?: number; md?: number; lg?: number },
    isCentered: boolean,
    theme?: SliderTheme,
    isInfinite?: boolean,
    isAuto?: boolean
  ) {
    this.itemCount = itemCount;
    this.itemInView = itemInView;
    this.isCentered = isCentered;
    this.theme = theme || SliderTheme.DEFAULT;
    this.isInfinite = isInfinite || false;
    this.isAuto = isAuto || false;
  }
}

export enum SliderTheme {
  DEFAULT = 'default',
  PRIMARY = 'primary',
}
