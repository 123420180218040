import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '../../models/breadcrumb';
import { MainService } from '../../services/main.service';
import { Router } from '@angular/router';
import { Doctor } from 'src/app/models/doctor';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-find-doctors',
  templateUrl: './find-doctors.component.html',
  styleUrls: ['./find-doctors.component.scss'],
})
export class FindDoctorsComponent implements OnInit {
  breadcrumb: Breadcrumb[];
  doctors: Doctor[];
  filteredDoctors$!: Observable<Doctor[]>;
  resultsAvaiable: boolean = false;
  private searchDoctors$ = new Subject<string>();
  form: FormGroup;
  message: string = '';

  constructor(private mainService: MainService, private router: Router) {}

  ngOnInit(): void {
    this.mainService.getBreadcrumb(this.router.url).subscribe((data) => {
      this.breadcrumb = data;
    });

    this.mainService.getDoctors().subscribe((data) => {
      this.doctors = data;
    });

    this.initForm();

    // Subscribe to value changes
    this.form.valueChanges.subscribe(() => {
      this.searchDoctors$.next(this.form.getRawValue());
    });

    this.filteredDoctors$ = this.searchDoctors$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(() => {
        this.resultsAvaiable = false;
        this.message = '';

        const category: string[] = [
          this.form.get('categoryDoctor').value && 'felnott_haziorvos',
          this.form.get('categoryDoctorChild').value && 'gyerek_haziorvos',
          this.form.get('categoryDentist').value && 'felnott_fogorvos',
          this.form.get('categoryDentistChild').value && 'gyerek_fogorvos',
        ];
        const location: string = this.form
          .get('location')
          .value.toLocaleLowerCase();

        let filtered: Doctor[] = [];
        if (location.length > 1) {
          filtered = this.doctors.filter((doctor) => {
            return (
              category.includes(doctor.category) &&
              doctor.location.toLocaleLowerCase().includes(location)
            );
          });

          if (
            !(
              this.form.get('categoryDoctor').value ||
              this.form.get('categoryDoctorChild').value ||
              this.form.get('categoryDentist').value ||
              this.form.get('categoryDentistChild').value
            )
          ) {
            this.message = 'Legalább egy kategória kiválasztása szükséges!';
          } else if (filtered.length === 0) {
            this.message = 'Nincs találat!';
          }
          if (filtered.length > 0) {
            this.resultsAvaiable = true;
          }
        }

        console.log(filtered);

        return of(filtered);
      })
    );
  }

  initForm(): void {
    this.form = new FormGroup({
      categoryDoctor: new FormControl(''),
      categoryDoctorChild: new FormControl(''),
      categoryDentist: new FormControl(''),
      categoryDentistChild: new FormControl(''),
      location: new FormControl(''),
    });
  }

  mapDoctors(category: string): string {
    switch (category) {
      case 'felnott_haziorvos':
        return 'Felnőtt háziorvos';
      case 'gyerek_haziorvos':
        return 'Gyerek háziorvos';
      case 'felnott_fogorvos':
        return 'Felnőtt fogorvos';
      case 'gyerek_fogorvos':
        return 'Gyerek fogorvos';
      default:
        return '';
    }
  }
}
