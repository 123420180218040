import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';

import { Observable } from 'rxjs';
import { Article } from '../models/article';
import { ArticleCard } from '../models/article-card';
import { ArticleList } from '../models/article-list';
import { ArticleTypes } from '../models/article-types';
import { Breadcrumb } from '../models/breadcrumb';
import {
  CalendarEvent,
  CalendarEventCategories,
} from '../models/calendar-event';
import { Cookie } from '../models/cookie';
import { Footer } from '../models/footer';
import { Gallery } from '../models/gallery';
import { GalleryList } from '../models/gallery-list';
import { Header } from '../models/header';
import { Home } from '../models/home';
import { ImageFile } from '../models/image-file';
import { PhonebookWrapper } from '../models/phonebook';
import { Preview, PreviewType } from '../models/preview';
import { SearchItem } from '../models/search-result';
import { Static } from '../models/static';
import { Doctor } from '../models/doctor';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  constructor(private http: HttpClient, private router: Router) {}

  // accessibility
  private readonly _accessibilityView = new BehaviorSubject<boolean>(false);

  readonly accessibilityView$ = this._accessibilityView.asObservable();

  get accessibilityView() {
    return this._accessibilityView.getValue();
  }

  set accessibilityView(val: boolean) {
    this._accessibilityView.next(val);
  }

  toggleAccessibilityView() {
    this._accessibilityView.next(!this._accessibilityView.getValue());
  }

  // cookie
  private readonly _cookieOption = new BehaviorSubject<Cookie>(null);
  readonly cookieOption$ = this._cookieOption.asObservable();

  get cookieOption() {
    return this._cookieOption.getValue();
  }

  set cookieOption(cookie: Cookie) {
    this._cookieOption.next(cookie);
    localStorage.setItem('cookie', JSON.stringify(cookie));
  }

  public getCookie(): Cookie {
    const cookie = JSON.parse(localStorage.getItem('cookie'));
    this._cookieOption.next(cookie);
    return cookie;
  }

  // footer
  public getFooter(): Observable<Footer> {
    return this.http.get<Footer>('/lablec');
  }

  // header
  public getHeader(): Observable<Header> {
    return this.http.get<Header>('/fejlec');
  }

  public getNamedays(): Observable<any> {
    return this.http.get('assets/nevnapok.json');
  }

  // static page
  public getStaticPage(slug: string): Observable<Static> {
    return this.http.get<Static>('/static-pages' + slug);
  }

  // article
  public getArticleList(slug: string, query): Observable<ArticleList> {
    return this.http.get<ArticleList>('/article-lists' + slug, {
      params: query.params,
    });
  }

  public getArticlePage(slug: string): Observable<Article> {
    return this.http.get<Article>('/articles' + slug);
  }

  // search
  public getPageSearch(query: any) {
    return this.http.get('/search', { params: query.params });
  }

  // gallery
  public getGalleryList(slug: string, query): Observable<GalleryList> {
    return this.http.get<GalleryList>('/gallery-lists' + slug, {
      params: query.params,
    });
  }

  public getGallery(slug: string): Observable<Gallery> {
    return this.http.get<Gallery>('/galleries' + slug);
  }

  // home
  public getHome(): Observable<Home> {
    return this.http.get<Home>('/homepage');
  }

  public closeInfo() {
    sessionStorage.setItem('infoClosed', 'true');
  }

  public isInfoClosed() {
    return sessionStorage.getItem('infoClosed') === 'true';
  }

  // calendar
  public getCalendarEvents(params: HttpParams): Observable<CalendarEvent[]> {
    return this.http.get<CalendarEvent[]>('/calendar-events', {
      params: params,
    });
  }

  public getCalendarEventCategories(): Observable<CalendarEventCategories[]> {
    return this.http.get<CalendarEventCategories[]>(
      '/calendar-event-categories'
    );
  }

  public getCalendarEventsRecommendations(): Observable<CalendarEvent[]> {
    return this.http.get<CalendarEvent[]>(
      '/calendar-events/get-recommendations'
    );
  }

  public navigateToEventPage(): void {
    this.router.navigateByUrl('/calendar');
  }

  // sidebar
  public getSidebar() {
    return this.http.get('/sidebar');
  }

  // report-form
  public getReportFormCategories(): Observable<string[]> {
    return this.http.get<string[]>('/report-form-categories');
  }

  public postReportForm(payload: FormData) {
    return this.http.post('/report-form/send', payload);
  }

  // newsletter
  public postNewsletterSubscription(payload: {
    email: string;
    username: string;
  }) {
    return this.http.post('/subscribe', payload);
  }

  // phonebook
  public getPhonebooks(): Observable<PhonebookWrapper> {
    return this.http.get<PhonebookWrapper>('/phonebooks');
  }

  // breadcrumb
  public getBreadcrumb(slug: string): Observable<Breadcrumb[]> {
    return this.http.get<Breadcrumb[]>('/breadcrumb' + slug);
  }

  // 404
  public getPageNotFound(slug: string, query): Observable<{ slug: string }> {
    return this.http.get<{ slug: string }>(slug, { params: query.params });
  }

  // doctors
  public getDoctors() {
    return this.http.get<Doctor[]>('/doctors?_limit=-1');
  }

  // preview
  public transformArticleToPreview(
    article: ArticleCard,
    type?: ArticleTypes
  ): Preview {
    const p = new Preview();
    p.title = article.title;
    p.lead = type === ArticleTypes.OTHER ? article.lead : '';
    p.date = article.published_at;
    p.category = article.article_category.title;
    p.slug = article.slug;
    p.thumbnailSrc = this.getSmallestImageUrl(article.cover_image);
    p.thumbnailAlt = article.cover_image?.alternativeText;
    p.galleryId = article.article_media_content?.gallery_id;
    p.videoSlug = article.article_media_content?.slug;
    p.sourceType = PreviewType.ARTICLE;
    return p;
  }

  public transformStaticToPreview(staticPage: Static): Preview {
    const p = new Preview();
    p.title = staticPage.title;
    p.lead = staticPage.lead;
    p.date = staticPage.published_at;
    p.slug = staticPage.slug;
    p.thumbnailSrc = this.getSmallestImageUrl(staticPage.cover_image);
    p.thumbnailAlt = staticPage.cover_image?.alternativeText;
    p.sourceType = PreviewType.STATIC;
    return p;
  }

  public transformGalleryToPreview(gallery: Gallery): Preview {
    const p = new Preview();
    p.title = gallery.title;
    p.date = gallery.published_at;
    p.thumbnailSrc = this.getSmallestImageUrl(gallery.cover_image);
    p.thumbnailAlt = gallery.cover_image?.alternativeText;
    p.sourceType = PreviewType.GALLERY;
    p.images = gallery.image;
    return p;
  }

  public transformSearchItemToPreview(search: SearchItem): Preview {
    const p = new Preview();
    p.title = search.title;
    p.lead = search.text;
    p.slug = search.slug;
    if (!!search.cover_image) {
      p.thumbnailSrc = this.getSmallestImageUrl(search.cover_image);
      p.thumbnailAlt = search.cover_image?.alternativeText;
    }
    p.sourceType = PreviewType.SEARCH;
    return p;
  }

  public getSmallestImageUrl(image: ImageFile, minWidth?: number) {
    minWidth = minWidth || 0;
    if (!!image?.formats) {
      if (
        !!image.formats.thumbnail &&
        image.formats.thumbnail.width >= minWidth
      ) {
        return image.formats.thumbnail.url;
      } else if (
        !!image.formats.small &&
        image.formats.small.width >= minWidth
      ) {
        return image.formats.small.url;
      } else if (
        !!image.formats.medium &&
        image.formats.medium.width >= minWidth
      ) {
        return image.formats.medium.url;
      } else if (
        !!image.formats.large &&
        image.formats.large.width >= minWidth
      ) {
        return image.formats.large.url;
      }
    }
    return image?.url;
  }
}
