import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadingMarkerService } from './loading-marker.service';

@Injectable({ providedIn: 'root' })
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    private snackbar: MatSnackBar,
    private router: Router,
    private loadingMarkerService: LoadingMarkerService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loadingMarkerService.markerOn();

    const urlPrefix = req.url.startsWith('assets')
      ? ''
      : environment.backendUrl;

    const backendRequest = req.clone({
      url: urlPrefix + req.url,
    });

    return next.handle(backendRequest).pipe(
      catchError((err: HttpErrorResponse) => {
        console.error(err);
        if (err.status === 500) {
          this.snackbar.open(
            'Az adatok feldolgozása közben hiba történt. Kérjük próbálja újra később.',
            null,
            {
              duration: 5000,
              verticalPosition: 'bottom',
              horizontalPosition: 'right',
            }
          );
        } else if (err.status === 404) {
          this.snackbar.open('Az oldal nem található.', null, {
            duration: 5000,
            verticalPosition: 'bottom',
            horizontalPosition: 'right',
          });
          this.router.navigateByUrl('/');
        } else if (err.status === 504 || err.status === 503) {
          this.snackbar.open(
            'Az adatok lekérdezése közben hiba történt. Kérjük próbálja újra később.',
            null,
            {
              duration: 5000,
              verticalPosition: 'bottom',
              horizontalPosition: 'right',
            }
          );
        }
        return throwError(err);
      }),
      finalize(() => {
        this.loadingMarkerService.markerOff();
      })
    );
  }
}
