import { ImageFile } from './image-file';

export class Gallery {
  title: string;
  cover_image: ImageFile;
  image: ImageFile[];
  created_at: Date;
  published_at: Date;
  updated_at: Date;
}
