import {Component, Input, OnInit} from '@angular/core';
import {BannerStatic} from '../../models/banner-static';

@Component({
  selector: 'app-banner-static',
  templateUrl: './banner-static.component.html',
  styleUrls: ['./banner-static.component.scss']
})
export class BannerStaticComponent implements OnInit {
  @Input() banner: BannerStatic;

  constructor() { }

  ngOnInit(): void {
  }

}
