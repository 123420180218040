<div class="sidebar">
  <div class="sidebar__item sidebar__item--calendar">
    <div class="title">Eseménynaptár</div>
    <app-calendar
      (dateClicked)="calendarClicked($event)"
      (keyup.enter)="calendarClicked($event)"
      tabindex="0"
      role="button"
    ></app-calendar>
  </div>
  <div class="sidebar__item sidebar__item--action">
    <a routerLink="/phonebook" class="button">Telefonkönyv</a>
  </div>
  <div class="sidebar__item sidebar__item--phone">
    <app-phone></app-phone>
  </div>
</div>
