<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<app-main>
  <ng-container>
    <article class="event">
      <h1>Eseménynaptár</h1>
      <section class="event__calendar">
        <h2>Megjelenítendő események:</h2>
        <div class="event__categories">
          <form [formGroup]="categoryForm">
            <div class="event__category-list" formArrayName="categories">
              <div
                class="event__checkbox-container"
                *ngFor="let category of categories.controls; let i = index"
              >
                <input
                  id="category-{{ i }}"
                  type="checkbox"
                  [value]="eventCategories[i].id"
                  [formControlName]="i"
                />
                <label for="category-{{ i }}">
                  <span class="event__checkbox__checkmark"></span>
                  {{ eventCategories[i].name }}
                </label>
              </div>
            </div>
          </form>
        </div>
        <app-calendar
          [pageMode]="true"
          [categories]="selectedCategories"
          (dateClicked)="getEventsOfDay($event, eventlist)"
        ></app-calendar>
      </section>
      <section class="event__events" #eventlist>
        <div class="event event-card" *ngFor="let event of eventsOfDate">
          <h2 class="event-card__title">{{ event.title }}</h2>
          <p
            class="event-card__description"
            [innerText]="getDescription(event)"
          ></p>
          <div class="event__actions">
            <button
              type="button"
              class="event__action"
              *ngIf="event.description?.length > 175"
              (click)="event.descriptionFullView = !event.descriptionFullView"
              tabindex="0"
              role="button"
              [ngClass]="{
                'event__action--full': event.descriptionFullView,
                'event__action--short': !event.descriptionFullView
              }"
            >
              {{ event.descriptionFullView ? "Kevesebb" : "Bővebben" }}
            </button>
            <a
              [routerLink]="event.slug"
              class="button button--continue"
              *ngIf="
                (event.slug && event.description?.length < 176) ||
                (event.slug && event.descriptionFullView)
              "
              >Tovább</a
            >
          </div>
        </div>
      </section>
    </article>
  </ng-container>
  <ng-container aside>
    <app-sidebar-article></app-sidebar-article>
  </ng-container>
</app-main>
