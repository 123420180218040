<div class="static-banner">
  <div class="static-banner__title" *ngIf="banner.title">
    {{banner.title}}
  </div>
  <div class="static-banner__text" *ngIf="banner.text" [innerHtml]="banner.text">
  </div>
  <div class="static-banner__file" *ngFor="let file of banner.files">
    <a target="_blank" [href]="file.url" [download]="file.name" [type]="file.mime">
      {{file.name.split('.').slice(0, -1).join('.')}}
    </a>
  </div>
</div>
