import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { GalleryDialogComponent } from 'src/app/components/dialogs/gallery-dialog/gallery-dialog.component';
import { ArticleTypes } from 'src/app/models/article-types';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { Gallery } from 'src/app/models/gallery';
import { Preview, PreviewType } from 'src/app/models/preview';
import { SearchResult } from 'src/app/models/search-result';
import { DialogService } from 'src/app/services/dialog.service';
import { LoadingMarkerService } from 'src/app/services/loading-marker.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit {
  breadcrumb: Breadcrumb[];
  results: SearchResult;
  slug: string;
  query;
  isLoading: boolean;

  constructor(
    public mainService: MainService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.mainService
      .getBreadcrumb('/' + this.route.snapshot.url)
      .subscribe((data) => {
        this.breadcrumb = data;
      });

    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.isLoading = true;
      this.query = params;
      this.mainService.getPageSearch(params).subscribe(
        (data: any) => {
          this.results = data;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    });
  }

  openGallery(gallery: Gallery) {
    this.dialogService.openModalWithParams(GalleryDialogComponent, gallery);
  }

  navToPage(ev) {
    let params = Object.assign({}, this.query.params);
    params.currentPage = ev;
    const urlString = this.route.snapshot.url.join('/');
    this.router.navigate([urlString], { queryParams: params }); // to change url
  }
}
