<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<app-main>
  <ng-container>
    <div class="title narrow">Ebösszeírás bejelentő űrlap</div>
    <div class="report-form">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" id="report-form">
        <div class="title--sub">Tulajdonosra, ebtartóra vonatkozó adatok</div>
        <label>
          <span>Eb tulajdonosának neve <span class="required">*</span></span>
          <input
            type="text"
            name="owner_name"
            id="owner_name"
            formControlName="owner_name"
            required
            [ngClass]="{ error: isInvalid('owner_name') }"
          />
          <div class="error-text" *ngIf="isInvalid('owner_name')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label>
          <span>Eb tulajdonosának címe <span class="required">*</span></span>
          <input
            type="text"
            name="owner_address"
            id="owner_address"
            formControlName="owner_address"
            required
            [ngClass]="{ error: isInvalid('owner_address') }"
          />
          <div class="error-text" *ngIf="isInvalid('owner_address')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label>
          <span>Ebtartó neve <span class="required">*</span></span>
          <input
            type="text"
            name="carrier_name"
            id="carrier_name"
            formControlName="carrier_name"
            required
            [ngClass]="{ error: isInvalid('carrier_name') }"
          />
          <div class="error-text" *ngIf="isInvalid('carrier_name')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label>
          <span>Ebtartó címe <span class="required">*</span></span>
          <input
            type="text"
            name="carrier_address"
            id="carrier_address"
            formControlName="carrier_address"
            required
            [ngClass]="{ error: isInvalid('carrier_address') }"
          />
          <div class="error-text" *ngIf="isInvalid('carrier_address')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label class="half-width">
          <span>Ebtartó telefonszáma <span class="required">*</span></span>
          <input
            type="text"
            name="carrier_phone_number"
            id="carrier_phone_number"
            formControlName="carrier_phone_number"
            required
            [ngClass]="{ error: isInvalid('carrier_phone_number') }"
          />
          <div class="error-text" *ngIf="isInvalid('carrier_phone_number')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label class="half-width">
          <span>Ebtartó email címe <span class="required">*</span></span>
          <input
            type="email"
            name="carrier_email"
            id="carrier_email"
            formControlName="carrier_email"
            required
            [ngClass]="{ error: isInvalid('carrier_email') }"
          />
          <div class="error-text" *ngIf="isInvalid('carrier_email')">
            A mező kitöltése kötelező
          </div>
          <div
            class="error-text"
            *ngIf="isInvalid('carrier_email') && form.get('carrier_email').errors?.email"
          >
            Nem megfelelő email formátum
          </div>
        </label>

        <div class="title--sub">A tartott ebre vonatkozó általános adatok</div>
        <label class="half-width">
          <span>Fajtája <span class="required">*</span></span>
          <input
            type="text"
            name="dog_breed"
            id="dog_breed"
            formControlName="dog_breed"
            required
            [ngClass]="{ error: isInvalid('dog_breed') }"
          />
          <div class="error-text" *ngIf="isInvalid('dog_breed')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label class="half-width">
          <span>Ivara <span class="required">*</span></span>
          <input
            type="text"
            name="dog_sex"
            id="dog_sex"
            formControlName="dog_sex"
            required
            [ngClass]="{ error: isInvalid('dog_sex') }"
          />
          <div class="error-text" *ngIf="isInvalid('dog_sex')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label class="half-width">
          <span>Színe <span class="required">*</span></span>
          <input
            type="text"
            name="dog_color"
            id="dog_color"
            formControlName="dog_color"
            required
            [ngClass]="{ error: isInvalid('dog_color') }"
          />
          <div class="error-text" *ngIf="isInvalid('dog_color')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label class="half-width">
          <span>Születési ideje <span class="required">*</span></span>
          <mat-form-field appearance="fill">
            <mat-label style="display: flex">Válasszon dátumot</mat-label>
            <input
              matInput
              [matDatepicker]="dogBirthDateDatePicker"
              name="dog_birth_date"
              id="dog_birth_date"
              formControlName="dog_birth_date"
              [ngClass]="{ error: isInvalid('dog_birth_date') }"
              (click)="dogBirthDateDatePicker.open()"
            >
            <mat-datepicker #dogBirthDateDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
          <div class="error-text" *ngIf="isInvalid('dog_birth_date')">
            A mező kitöltése kötelező
          </div>
        </label>



        <label class="half-width">
          <span>Hívóneve <span class="required">*</span></span>
          <input
            type="text"
            name="dog_name"
            id="dog_name"
            formControlName="dog_name"
            required
            [ngClass]="{ error: isInvalid('dog_name') }"
          />
          <div class="error-text" *ngIf="isInvalid('dog_name')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label>
          <span>Tartási helye <span class="required">*</span></span>
          <input
            type="text"
            name="dog_address"
            id="dog_address"
            formControlName="dog_address"
            required
            [ngClass]="{ error: isInvalid('dog_address') }"
          />
          <div class="error-text" *ngIf="isInvalid('dog_address')">
            A mező kitöltése kötelező
          </div>
        </label>

        <div class="title--sub">A tartott ebre vonatkozó speciális adatok</div>
        <div class="title--sub--sub">Transzponderrel ellátott eb esetén</div>
        <label class="half-width">
          <span>Beültetett mikrochip sorszáma (15 számjegy)</span>
          <input
            type="text"
            name="chip_serial_number"
            id="chip_serial_number"
            formControlName="chip_serial_number"
          />
        </label>
        <label class="half-width">
          <span>Beültetés időpontja</span>
          <mat-form-field appearance="fill">
            <mat-label style="display: flex">Válasszon dátumot</mat-label>
            <input
              matInput
              [matDatepicker]="chipImplantationDateDatePicker"
              name="chip_implantation_date"
              id="chip_implantation_date"
              formControlName="chip_implantation_date"
              (click)="chipImplantationDateDatePicker.open()"
            >
            <mat-datepicker #chipImplantationDateDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
        </label>
        <label>
          <span>Beültetést végző szolgáltató állatorvos neve</span>
          <input
            type="text"
            name="chip_implantation_doctor_name"
            id="chip_implantation_doctor_name"
            formControlName="chip_implantation_doctor_name"
          />
        </label>
        <label>
          <span>Állatorvos kamarai bélyegzőjének száma</span>
          <input
            type="text"
            name="chip_implantation_doctor_id"
            id="chip_implantation_doctor_id"
            formControlName="chip_implantation_doctor_id"
          />
        </label>
        <div class="title--sub--sub">Ivartalanított eb esetén</div>
        <label class="half-width">
          <span>Ivartalanítás időpontja</span>
          <mat-form-field appearance="fill">
            <mat-label style="display: flex">Válasszon dátumot</mat-label>
            <input
              matInput
              [matDatepicker]="sterilizationDateDatePicker"
              name="sterilization_date"
              id="sterilization_date"
              formControlName="sterilization_date"
              (click)="sterilizationDateDatePicker.open()"
            >
            <mat-datepicker #sterilizationDateDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
        </label>
        <label>
          <span>Ivartalanítást végző állatorvos neve</span>
          <input
            type="text"
            name="sterilization_doctor_name"
            id="sterilization_doctor_name"
            formControlName="sterilization_doctor_name"
          />
        </label>
        <label>
          <span>Állatorvos kamarai bélyegzőjének száma</span>
          <input
            type="text"
            name="sterilization_doctor_id"
            id="sterilization_doctor_id"
            formControlName="sterilization_doctor_id"
          />
        </label>
        <div class="title--sub--sub">Kisállatútlevéllel rendelkező eb esetén</div>
        <label class="half-width">
          <span>Útlevél száma</span>
          <input
            type="text"
            name="dog_passport_number"
            id="dog_passport_number"
            formControlName="dog_passport_number"
          />
        </label>
        <label class="half-width">
          <span>Útlevél kiállításának időpontja</span>
          <mat-form-field appearance="fill">
            <mat-label style="display: flex">Válasszon dátumot</mat-label>
            <input
              matInput
              [matDatepicker]="dogPassportDateDatePicker"
              name="dog_passport_date"
              id="dog_passport_date"
              formControlName="dog_passport_date"
              (click)="dogPassportDateDatePicker.open()"
            >
            <mat-datepicker #dogPassportDateDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
        </label>
        <label>
          <span>Útlevelet kiállító szolgáltató állatorvos neve</span>
          <input
            type="text"
            name="dog_passport_doctor_name"
            id="dog_passport_doctor_name"
            formControlName="dog_passport_doctor_name"
          />
        </label>
        <label>
          <span>Állatorvos kamarai bélyegzőjének száma</span>
          <input
            type="text"
            name="dog_passport_doctor_id"
            id="dog_passport_doctor_id"
            formControlName="dog_passport_doctor_id"
          />
        </label>
        <div class="title--sub">Az eb oltására vonatkozó adatok</div>
        <label class="half-width">
          <span>Az eb oltási könyvének száma <span class="required">*</span></span>
          <input
            type="text"
            name="vaccination_book_id"
            id="vaccination_book_id"
            formControlName="vaccination_book_id"
            required
            [ngClass]="{ error: isInvalid('vaccination_book_id') }"
          />
          <div class="error-text" *ngIf="isInvalid('vaccination_book_id')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label>
          <span>Oltási könyvet kiadó állatorvos neve <span class="required">*</span></span>
          <input
            type="text"
            name="vaccination_book_doctor_name"
            id="vaccination_book_doctor_name"
            formControlName="vaccination_book_doctor_name"
            required
            [ngClass]="{ error: isInvalid('vaccination_book_doctor_name') }"
          />
          <div class="error-text" *ngIf="isInvalid('vaccination_book_doctor_name')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label>
          <span>Az állatorvos kamarai bélyegzőjének száma <span class="required">*</span></span>
          <input
            type="text"
            name="vaccination_book_doctor_id"
            id="vaccination_book_doctor_id"
            formControlName="vaccination_book_doctor_id"
            required
            [ngClass]="{ error: isInvalid('vaccination_book_doctor_id') }"
          />
          <div class="error-text" *ngIf="isInvalid('vaccination_book_doctor_id')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label class="half-width">
          <span>Az eb utolsó veszettség elleni védőoltásának időpontja <span class="required">*</span></span>
          <mat-form-field appearance="fill">
            <mat-label style="display: flex">Válasszon dátumot</mat-label>
            <input
              matInput
              [matDatepicker]="lastVaccinationDateDatePicker"
              name="last_vaccination_date"
              id="last_vaccination_date"
              formControlName="last_vaccination_date"
              [ngClass]="{ error: isInvalid('last_vaccination_date') }"
              (click)="lastVaccinationDateDatePicker.open()"
            >
            <mat-datepicker #lastVaccinationDateDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
          <div class="error-text" *ngIf="isInvalid('last_vaccination_date')">
            A mező kitöltése kötelező
          </div>
        </label>

        <label>
          <span>Az oltóanyag neve, gyártási száma <span class="required">*</span></span>
          <input
            type="text"
            name="last_vaccination_name_and_id"
            id="last_vaccination_name_and_id"
            formControlName="last_vaccination_name_and_id"
            required
            [ngClass]="{ error: isInvalid('last_vaccination_name_and_id') }"
          />
          <div class="error-text" *ngIf="isInvalid('last_vaccination_name_and_id')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label>
          <span>Az oltást végző állatorvos neve <span class="required">*</span></span>
          <input
            type="text"
            name="last_vaccination_doctor_name"
            id="last_vaccination_doctor_name"
            formControlName="last_vaccination_doctor_name"
            required
            [ngClass]="{ error: isInvalid('last_vaccination_doctor_name') }"
          />
          <div class="error-text" *ngIf="isInvalid('last_vaccination_doctor_name')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label>
          <span>Az állatorvos kamarai bélyegzőjének száma <span class="required">*</span></span>
          <input
            type="text"
            name="last_vaccination_doctor_id"
            id="last_vaccination_doctor_id"
            formControlName="last_vaccination_doctor_id"
            required
            [ngClass]="{ error: isInvalid('last_vaccination_doctor_id') }"
          />
          <div class="error-text" *ngIf="isInvalid('last_vaccination_doctor_id')">
            A mező kitöltése kötelező
          </div>
        </label>

        <div class="title--sub">Egyéb adatok</div>
        <label class="select-container">
          <span>A veszettség szempontjából aggályos eb megfigyelés státusza</span>
          <select
            name="rabies_status"
            id="rabies_status"
            formControlName="rabies_status"
          >
            <option *ngFor="let rabiesStatus of ['Megfigyelt', 'Nem megfigyelt']" [ngValue]="rabiesStatus">
              {{ rabiesStatus }}
            </option>
          </select>
        </label>
        <label class="half-width">
          <span>Kezdő időpontja</span>
          <mat-form-field appearance="fill">
            <mat-label style="display: flex">Válasszon dátumot</mat-label>
            <input
              matInput
              [matDatepicker]="rabiesObservationStartDateDatePicker"
              name="rabies_observation_start_date"
              id="rabies_observation_start_date"
              formControlName="rabies_observation_start_date"
              [ngClass]="{ error: isInvalid('rabies_observation_start_date') }"
              (click)="rabiesObservationStartDateDatePicker.open()"
            >
            <mat-datepicker #rabiesObservationStartDateDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
        </label>
        <label class="half-width">
          <span>Időtartama</span>
          <input
            type="text"
            name="rabies_observation_period"
            id="rabies_observation_period"
            formControlName="rabies_observation_period"
            [ngClass]="{ error: isInvalid('rabies_observation_period') }"
          />
        </label>
        <label class="half-width select-container">
          <span>Az eb veszélyesség minősítése</span>
          <select
            name="dog_danger_status"
            id="dog_danger_status"
            formControlName="dog_danger_status"
          >
            <option *ngFor="let dogDangerStatus of ['Igen', 'Nem']" [ngValue]="dogDangerStatus">
              {{ dogDangerStatus }}
            </option>
          </select>
        </label>

        <label class="half-width">
          <span>Ha igen, akkor a veszélyesség minősítés időpontja</span>
          <mat-form-field appearance="fill">
            <mat-label style="display: flex">Válasszon dátumot</mat-label>
            <input
              matInput
              [matDatepicker]="dogDangerStatusDatePicker"
              name="dog_danger_status_date"
              id="dog_danger_status_date"
              formControlName="dog_danger_status_date"
              (click)="dogDangerStatusDatePicker.open()"
            />
            <mat-datepicker #dogDangerStatusDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
        </label>
        <div class="title--sub">Vészhelyzet esetén értesítendő személy adatai</div>
        <label>
          <span>Név</span>
          <input
            type="text"
            name="alerting_name"
            id="alerting_name"
            formControlName="alerting_name"
          />
        </label>
        <label>
          <span>Lakcím</span>
          <input
            type="text"
            name="alerting_address"
            id="alerting_address"
            formControlName="alerting_address"
          />
        </label>
        <label class="half-width">
          <span>Telefonszám</span>
          <input
            type="text"
            name="alerting_phone_number"
            id="alerting_phone_number"
            formControlName="alerting_phone_number"
          />
        </label>
        <label>
          <mat-checkbox
            class="example-margin"
            name="alerting_data_consent"
            id="alerting_data_consent"
            formControlName="alerting_data_consent"
          >
            <p style="white-space: normal">
              Nyilatkozom, hogy a vészhelyzet esetén értesítendő személy hozzájárult adatainak közléséhez és az eb nyilvántartásban való rögzítéséhez.
            </p>
            </mat-checkbox>
          <div class="error-text" *ngIf="alertingDataConsentIsRequired()">
            A nyilatkozat elfogadása kötelező
          </div>
        </label>

        <div class="report-form__captcha">
          <app-recaptcha
            [key]="siteKey"
            formControlName="recaptcha"
          ></app-recaptcha>
        </div>

        <div class="button-container">
          <button type="submit" form="report-form" [disabled]="!form.valid || alertingDataConsentIsRequired()">
            Elküldés
          </button>
          <button type="button" (click)="resetForm()">Mégse</button>
        </div>
      </form>
    </div>
  </ng-container>
  <ng-container aside>
    <app-sidebar-article></app-sidebar-article>
  </ng-container>
</app-main>
