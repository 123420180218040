import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { SearchResult } from 'src/app/models/search-result';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.scss'],
})
export class CaseComponent implements OnInit {
  breadcrumb: Breadcrumb[];
  results: SearchResult;
  slug: string;
  query;
  searchQuery: string;
  isLoading: boolean;
  constructor(
    public mainService: MainService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.mainService
      .getBreadcrumb('/' + this.route.snapshot.url)
      .subscribe((data) => {
        this.breadcrumb = data;
      });

    this.route.queryParamMap
      .pipe(
        switchMap((params: ParamMap) => {
          if (!!params.keys.length) {
            this.isLoading = true;
          }
          this.query = params;
          return this.mainService.getPageSearch(params);
        })
      )
      .subscribe((data: any) => {
        this.results = data;
        this.isLoading = false;
      });
  }

  onSearch() {
    if (!!this.searchQuery) {
      this.router.navigate(['/case/'], {
        queryParams: { keyword: this.searchQuery, isCaseContent: true },
      });
    }
  }

  navToPage(ev) {
    let params = Object.assign({}, this.query.params);
    params.currentPage = ev;
    const urlString = this.route.snapshot.url.join('/');
    this.router.navigate([urlString], { queryParams: params }); // to change url
  }
}
