<app-common-report-form
  [formTitle]="'Állatvédelmi bejelentő űrlap'"
  [customRequiredFields]="[]"
  [formCategory]="'ANIMAL_PROTECTION'"
  [subTitle]="
    '<p>Ezen a felületen egyszerűen megtehetők az állatvédelmi tárgyú közérdekű bejelentések és csatolhatók a jogszabálysértést alátámasztó bizonyítékok.</p>' +
    '<p>Tájékoztatjuk Önöket, hogy ezen a felületen kizárólag a <strong>közérdekű állatvédelmi tárgyú</strong> bejelentéseiket tehetik meg (közterületen kóborló eb, rossz körülmények között tartott állat, állat bántalmazása, állat kínzása). Amennyiben a szomszéd állattartása zavarja, az ingatlan békés birtoklásában akadályozza Önöket, a jogszabályi előírásoknak megfelelően kezdeményezhetnek hatósági eljárást!</p>' +
    '<p>Fontos tudnivaló, hogy az ezen a felületen tett bejelentés esetében a bejelentő a vonatkozó jogszabály szerint nem minősül ügyfélnek. A bejelentésben foglaltak kivizsgálásra kerülnek, azonban a bejelentő nem értesül a hatóság döntéséről.</p>' +
    '<p>A személyes adatok megadása <strong>nem kötelező</strong>!</p>'
  "
></app-common-report-form>
