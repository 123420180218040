import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { Phonebook } from 'src/app/models/phonebook';
import { MainService } from 'src/app/services/main.service';
declare var $;
@Component({
  selector: 'app-phonebook',
  templateUrl: './phonebook.component.html',
  styleUrls: ['./phonebook.component.scss'],
})
export class PhonebookComponent implements OnInit {
  @ViewChild('dataTable', { static: false }) table;
  dataTable: any;
  dataTableApi;
  query;
  path;
  phonebooks: Phonebook[];
  breadcrumb: Breadcrumb[];
  queryName = 'query';

  isPrivateView = false;
  tableColumns = [
    { data: 'organization', title: 'Szervezeti egység' },
    { data: 'name', title: 'Név' },
    { data: 'position', title: 'Munkakör' },
    { data: 'phone_number', title: 'Hívószám' },
  ];

  constructor(
    private mainService: MainService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.mainService.getBreadcrumb(this.router.url).subscribe((data) => {
      this.breadcrumb = data;
    });
    this.mainService.getPhonebooks().subscribe((data) => {
      this.phonebooks = data.phonebooks;

      if (data.isPrivateView) {
        this.isPrivateView = true;
        this.tableColumns.push(
          { data: 'mobile_phone_number', title: 'Mobilszám' },
          { data: 'room_number', title: 'Szobaszám' },
          { data: 'extension', title: 'Mellék' },
          { data: 'image', title: 'Kép' }
        );
      }
      this.initDataTable();

      this.query = this.route.snapshot.queryParamMap.get(this.queryName);
      this.path = this.route.snapshot.routeConfig.path;

      this.dataTableApi.on('search.dt', (e, s) => {
        if (!!s.oPreviousSearch.sSearch) {
          this.router.navigateByUrl(
            this.path + '?' + this.queryName + '=' + s.oPreviousSearch.sSearch
          );
        } else {
          this.router.navigateByUrl(this.path);
        }
      });
      if (!!this.query) {
        this.dataTableApi.search(this.query).draw();
      }
    });
  }

  calendarClicked(ev) {
    this.mainService.navigateToEventPage();
  }

  initDataTable(): void {
    const columnDefsInit: any[] = [
      { name: 'engine', targets: 0 },
      { name: 'browser', targets: 1 },
      { name: 'platform', targets: 2 },
      { name: 'version', targets: 3 },
    ];

    if (this.isPrivateView) {
      columnDefsInit.push({
        name: 'image',
        targets: 7,
        data: 'img',
        render: function (url, type, full) {
          return '<img src="' + url + '">';
        },
      });
    }

    // add custom sorting for DataTable (https://datatables.net/blog/2017-02-28#Interface-with-DataTables)
    $.fn.dataTable.ext.order.intl = function (locales, options) {
      if (window.Intl) {
        var collator = new window.Intl.Collator(locales, options);
        var types = $.fn.dataTable.ext.type;

        delete types.order['string-pre'];
        types.order['string-asc'] = collator.compare;
        types.order['string-desc'] = function (a, b) {
          return collator.compare(a, b) * -1;
        };
      }
    };

    $.fn.dataTable.ext.order.intl('hu');
    this.dataTable = $(this.table.nativeElement);
    this.dataTableApi = this.dataTable.DataTable({
      responsive: true,
      data: this.phonebooks,
      columns: this.tableColumns,
      columnDefs: columnDefsInit,
      pager: {
        numbers_length: 5,
      },
      language: {
        emptyTable: 'Nincs rendelkezésre álló adat',
        info: 'Megjelenítve: _START_ - _END_, összesen: _TOTAL_ bejegyzés',
        infoEmpty: 'Nincs találat',
        infoFiltered: '(_MAX_ összes rekord közül szűrve)',
        infoThousands: ' ',
        lengthMenu: 'Mutass _MENU_ bejegyzést',
        loadingRecords: 'Betöltés...',
        processing: 'Feldolgozás...',
        search: 'Keresés a telefonkönyvben:',
        zeroRecords: 'Nincs a keresésnek megfelelő találat',
        paginate: {
          first: 'Első',
          previous: 'Előző',
          next: 'Következő',
          last: 'Utolsó',
        },
        aria: {
          sortAscending: ': aktiválja a növekvő rendezéshez',
          sortDescending: ': aktiválja a csökkenő rendezéshez',
        },
        select: {
          rows: {
            _: '%d sor kiválasztva',
            '1': '1 sor kiválasztva',
          },
          '1': '%d sor kiválasztva',
          _: '%d sor kiválasztva',
          cells: {
            '1': '1 cella kiválasztva',
            _: '%d cella kiválasztva',
          },
          columns: {
            '1': '1 oszlop kiválasztva',
            _: '%d oszlop kiválasztva',
          },
        },
        buttons: {
          colvis: 'Oszlopok',
          copy: 'Másolás',
          copyTitle: 'Vágólapra másolás',
          copySuccess: {
            _: '%d sor másolva',
            '1': '1 sor másolva',
          },
          collection: 'Gyűjtemény',
          colvisRestore: 'Oszlopok visszaállítása',
          copyKeys:
            'Nyomja meg a CTRL vagy u2318 + C gombokat a táblázat adatainak a vágólapra másolásához.<br /><br />A megszakításhoz kattintson az üzenetre vagy nyomja meg az ESC billentyűt.',
          csv: 'CSV',
          excel: 'Excel',
          pageLength: {
            '-1': 'Összes sor megjelenítése',
            '1': '1 sor megjelenítése',
            _: '%d sor megjelenítése',
          },
          pdf: 'PDF',
          print: 'Nyomtat',
        },
        autoFill: {
          cancel: 'Megszakítás',
          fill: 'Összes cella kitöltése a következővel: <i>%d</i>',
          fillHorizontal: 'Cellák vízszintes kitöltése',
          fillVertical: 'Cellák függőleges kitöltése',
        },
        searchBuilder: {
          add: 'Feltétel hozzáadása',
          button: {
            '0': 'Keresés konfigurátor',
            _: 'Keresés konfigurátor (%d)',
          },
          clearAll: 'Összes feltétel törlése',
          condition: 'Feltétel',
          conditions: {
            date: {
              after: 'Után',
              before: 'Előtt',
              between: 'Között',
              empty: 'Üres',
              equals: 'Egyenlő',
              not: 'Nem',
              notBetween: 'Kívül eső',
              notEmpty: 'Nem üres',
            },
            number: {
              between: 'Között',
              empty: 'Üres',
              equals: 'Egyenlő',
              gt: 'Nagyobb mint',
              gte: 'Nagyobb vagy egyenlő mint',
              lt: 'Kisebb mint',
              lte: 'Kisebb vagy egyenlő mint',
              not: 'Nem',
              notBetween: 'Kívül eső',
              notEmpty: 'Nem üres',
            },
            string: {
              contains: 'Tartalmazza',
              empty: 'Üres',
              endsWith: 'Végződik',
              equals: 'Egyenlő',
              not: 'Nem',
              notEmpty: 'Nem üres',
              startsWith: 'Kezdődik',
            },
          },
          data: 'Adat',
          deleteTitle: 'Feltétel törlése',
          logicAnd: 'És',
          logicOr: 'Vagy',
          title: {
            '0': 'Keresés konfigurátor',
            _: 'Keresés konfigurátor (%d)',
          },
          value: 'Érték',
        },
        searchPanes: {
          clearMessage: 'Szűrők törlése',
          collapse: {
            '0': 'Szűrőpanelek',
            _: 'Szűrőpanelek (%d)',
          },
          count: '{total}',
          countFiltered: '{shown} ({total})',
          emptyPanes: 'Nincsenek szűrőpanelek',
          loadMessage: 'Szűrőpanelek betöltése',
          title: 'Aktív szűrőpanelek: %d',
        },
        datetime: {
          previous: 'Előző',
          next: 'Következő',
          hours: 'Óra',
          minutes: 'Perc',
          seconds: 'Másodperc',
          amPm: ['de.', 'du.'],
        },
        editor: {
          close: 'Bezárás',
          create: {
            button: 'Új',
            title: 'Új',
            submit: 'Létrehozás',
          },
          edit: {
            button: 'Módosítás',
            title: 'Módosítás',
            submit: 'Módosítás',
          },
          remove: {
            button: 'Törlés',
            title: 'Törlés',
            submit: 'Törlés',
          },
        },
      },
    });
  }
}
