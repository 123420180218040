<div
  *ngIf="!!options"
  class="slider"
  [ngClass]="{ 'slider--primary': options?.theme === themes.PRIMARY }"
  [style]="{
    '--in-view': inView,
    '--page': page,
    '--time': sliderTransitionTime
  }"
>
  <a
    role="button"
    class="slider__stepper slider__stepper--left"
    (click)="prevSlide()"
    *ngIf="inView < options.itemCount"
  >
    <span aria-hidden="true" class="slider__step slider__step--prev"></span>
    <span class="sr-only">Előző</span>
  </a>
  <div class="slider__container">
    <div
      class="slider__track"
      [ngClass]="{ 'slider__track--centered': options?.isCentered }"
    >
      <ng-content></ng-content>
      <div *ngIf="hasFake"></div>
    </div>
  </div>
  <a
    role="button"
    class="slider__stepper slider__stepper--right"
    (click)="nextSlide()"
    *ngIf="inView < options.itemCount"
  >
    <span aria-hidden="true" class="slider__step slider__step--next"></span>
    <span class="sr-only">Következő</span>
  </a>
</div>
