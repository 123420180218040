<div class="app">
    <div class="app__head">
        <app-header></app-header>
    </div>
    <div class="app__content" [ngClass]="navbarfixed?'fixed':'nofixed'">
        <router-outlet></router-outlet>
    </div>
    <div class="app__footer">
        <app-footer></app-footer>
    </div>
</div>
