import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LoadingMarkerService {

    private _loadingMarker: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loadingMarkerStateEmitter: Observable<boolean> = this._loadingMarker.asObservable();

    markerOn() {
        this._loadingMarker.next(true);
    }

    markerOff() {
        this._loadingMarker.next(false);
    }

}
