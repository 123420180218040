<app-breadcrumb [breadcrumb]="document?.breadcrumb"></app-breadcrumb>
<app-main>
  <ng-container>
    <article>
      <div class="document__cover" *ngIf="!!document?.cover_image?.url">
        <img
          [src]="mainService.getSmallestImageUrl(document?.cover_image, maxImgWidth)"
          [alt]="document?.cover_image?.alternativeText"
        />
      </div>
      <h1>{{ document?.title }}</h1>
      <p class="document__lead">{{ document?.lead }}</p>
      <app-content [contents]="document?.content"></app-content>
      <h2 *ngIf="!!document?.files && document.files.length > 0" class="document__download">Letölthető anyagok</h2>
      <div class="document__file" *ngFor="let file of document?.files">
        <a target="_blank" [href]="file.url">
          {{ file.name.split(".").slice(0, -1).join(".") }}
        </a>
      </div>
    </article>
  </ng-container>
  <ng-container aside>
    <app-sidebar-article></app-sidebar-article>
  </ng-container>
  <ng-container wide *ngIf="!!gallery">
    <app-slider [options]="sliderOptions">
      <div class="gallery-item" *ngFor="let image of gallery.image">
        <button
          type="button"
          class="gallery-item__button"
          (click)="openGallery(gallery)"
        >
          <img [src]="mainService.getSmallestImageUrl(image)" [alt]="image.alternativeText" />
        </button>
      </div>
    </app-slider>
  </ng-container>
</app-main>
