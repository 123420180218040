import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ResponsiveService {
  public screenWidth: string;
  public containerWidth: number;
  public MOBILE = 'mobile';
  public TABLET = 'tablet';
  public DESKTOP_SMALL = 'desktop_small';
  public DESKTOP = 'desktop';

  tabletWidth = 768;
  desktopSmallWidth = 992;
  desktopWidth = 1200;

  private containerWidthSm = 0;
  private containerWidthMd = 760;
  private containerWidthLg = 990;
  private containerWidthXl = 1012;

  constructor() {
    this.checkWidth();
  }

  public checkWidth(): string {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      );

    const width = window.innerWidth;
    if (width < this.tabletWidth) {
      this.screenWidth = this.MOBILE;
    } else if (
      (isTablet && width <= this.desktopSmallWidth) ||
      (width >= this.tabletWidth && width <= this.desktopSmallWidth)
    ) {
      this.screenWidth = this.TABLET;
    } else if (width > this.desktopSmallWidth && width <= this.desktopWidth) {
      this.screenWidth = this.DESKTOP_SMALL;
    } else {
      this.screenWidth = this.DESKTOP;
    }

    // set container width
    switch (this.screenWidth) {
      case this.MOBILE:
        this.containerWidth = this.containerWidthSm || width;
        break;
      case this.TABLET:
        this.containerWidth = this.containerWidthMd || width;
        break;
      case this.DESKTOP_SMALL:
        this.containerWidth = this.containerWidthLg || width;
        break;
      case this.DESKTOP:
        this.containerWidth = this.containerWidthXl || width;
        break;
      default:
        this.containerWidth = width;
        break;
    }
    
    return this.screenWidth;
    // console.log('2-in-ResponsiveService |', 'sreenWidth: ' + window.screen.width, '| innerWidth: ' + width, 'sreenType: ' + this.screenWidth, '| isTablet: ' + isTablet);
  }
}
