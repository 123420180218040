import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ArticleCard } from 'src/app/models/article-card';
import { ArticleList } from 'src/app/models/article-list';
import { ArticleTypes } from 'src/app/models/article-types';
import { Pagination } from 'src/app/models/pagination';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss'],
})
export class ArticleListComponent implements OnInit {
  articles: ArticleList;
  articleList: ArticleCard[];

  articleTypes = ArticleTypes;
  query;
  slug: string;

  constructor(
    public mainService: MainService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.query = this.route.snapshot.queryParamMap;
          this.slug = '/' + params.get('slug');
          return this.mainService.getArticleList(this.slug, this.query);
        })
      )
      .subscribe((data) => {
        this.articles = data;
        this.articleList = this.articles.data.data;
      });
  }

  navToPage(ev) {
    let params = Object.assign({}, this.query.params);
    params.currentPage = ev;
    this.mainService
      .getArticleList(this.slug, { params: params })
      .subscribe((data) => {
        this.articles = data;
        this.articleList = this.articles.data.data;
      });

    const urlString = this.route.snapshot.url.join('/');
    this.router.navigate([urlString], { queryParams: params }); // to change url
  }
}
