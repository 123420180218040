<nav
  class="pager"
  role="navigation"
  aria-label="Pagination Navigation"
  *ngIf="!!pager && pager.lastPage > 1"
>
  <ul class="pager__pages">
    <li class="pager__page previous">
      <button
        type="button"
        class="pager__button"
        (click)="navigateToPage(pager.currentPage - 1)"
        [disabled]="pager.currentPage <= 1"
      >
        <img
          _ngcontent-ngk-c60=""
          src="assets/icons/pager-previous.svg"
          alt="Előző oldal"
        />
      </button>
    </li>
    <li class="pager__page">
      <button
        type="button"
        class="pager__button"
        (click)="navigateToPage(1)"
        [ngClass]="{ 'pager__button--active': +pager.currentPage === 1 }"
      >
        1
      </button>
    </li>
    <li class="pager__page" *ngFor="let page of pagerPages">
      <button
        type="button"
        class="pager__button"
        (click)="navigateToPage(page)"
        [ngClass]="{ 'pager__button--active': +pager.currentPage === +page }"
        [disabled]="page === '...'"
      >
        {{ page }}
      </button>
    </li>
    <li class="pager__page">
      <button
        type="button"
        class="pager__button"
        (click)="navigateToPage(pager.lastPage)"
        [ngClass]="{
          'pager__button--active': +pager.currentPage === +pager.lastPage
        }"
      >
        {{ pager.lastPage }}
      </button>
    </li>
    <li class="pager__page next">
      <button
        type="button"
        class="pager__button"
        (click)="navigateToPage(+pager.currentPage + 1)"
        [disabled]="pager.currentPage >= pager.lastPage"
      >
        <img
          _ngcontent-ngk-c60=""
          src="assets/icons/pager-next.svg"
          alt="Következő oldal"
        />
      </button>
    </li>
  </ul>
</nav>
