<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<div class="container">
  <div class="title narrow">Telefonkönyv</div>
  <p id="tableDescription"></p>
  <table #dataTable aria-describedby="tableDescription">
    <th scope="col"></th>
  </table>

  <div *ngIf="isPrivateView" style="margin-top: 2rem;">
    <a
      class="footer__column__link"
      style="cursor: pointer;"
      href="assets/docs/Adatkezelési tájékoztató.pdf"
      target="_blank"
    >Adatkezelési tájékoztató megtekintése</a>
  </div>
</div>
