import {Component, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { MainService } from 'src/app/services/main.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-common-report-form',
  templateUrl: './common-report-form.component.html',
  styleUrls: ['./common-report-form.component.scss'],
})
export class CommonReportFormComponent implements OnInit {

  breadcrumb: Breadcrumb[];

  form: FormGroup;
  uploadedFile: File;

  siteKey = environment.siteKey;

  @Input() formTitle = '';
  @Input() formCategory = '';
  @Input() subTitle: string | undefined;

  @Input() customRequiredFields: string[] = [
    'first_name',
    'last_name',
    'zip_code',
    'city',
    'street',
    'phone_number',
    'email'
  ];

  validationKeys: any = {
    first_name: 'Bejelentő keresztneve',
    last_name: 'Bejelentő vezetékneve',
    zip_code: 'Irányítószám / HRSZ',
    city: 'Város',
    street: 'Utcanév, házszám, emelet, ajtószám',
    phone_number: 'Bejelentő telefonszáma',
    email: 'Bejelentő e-mail címe',
    address: 'Kapcsolódó helyszín címe',
    //  parcel_number: 'Kapcsolódó helyszín helyrajzi száma',
    description: 'Leírás',
    //  file: 'Melléklet',
    recaptcha: 'Captcha',
  };
  allowedFileTypes = [
    'application/msword',
    'application/zip',
    'image/png',
    'image/jpeg',
    'image/tiff',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];
  maximumFileSizeInBytes = 12000000; // 12 Mb

  constructor(
    private mainService: MainService,
    private snackbar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.mainService.getBreadcrumb(this.router.url).subscribe((data) => {
      this.breadcrumb = data;
    });

    this.initForm();
  }

  initForm(): void {
    this.form = new FormGroup({
      first_name: new FormControl(''),
      last_name: new FormControl(''),
      zip_code: new FormControl(''),
      city: new FormControl(''),
      street: new FormControl(''),
      phone_number: new FormControl(''),
      email: new FormControl('', Validators.email),
      address: new FormControl('', Validators.required),
      parcel_number: new FormControl(''),
      description: new FormControl('', Validators.required),
      file: new FormControl('', { validators: [this.fileValidator()] }),
      recaptcha: new FormControl('', Validators.required),
    });


    for (const controlName of this.customRequiredFields) {
      const validators = controlName === 'email' ? [Validators.required, Validators.email] : [Validators.required];
      this.form.controls[controlName].setValidators(validators);
    }
  }

  onSubmit(): void {
    const formData = new FormData();
    for (const key in this.form.getRawValue()) {
      formData.append(key, this.form.get(key).value);
    }
    formData.set('file', this.uploadedFile);
    formData.set('formCategory', this.formCategory);

    if (true || this.form.valid) {
      this.mainService.postReportForm(formData).subscribe(
        (resp) => {
          this.snackbar.open('Bejelentés elküldve.', null, {
            duration: 10000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
          });
        },
        (error) => {
          this.snackbar.open('Bejentés beküldése sikertelen.', 'Rendben', {
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
          });
        }
      );
    }
  }

  isInvalid(controlName: string): boolean {
    const control = this.form.get(controlName);
    return control.invalid && (control.dirty || control.touched);
  }

  uploadFile(file: File): void {
    this.uploadedFile = file; // set file data
    this.form.get('file').updateValueAndValidity();
  }

  resetFile(): void {
    this.form.get('file').setValue('');
    this.uploadedFile = null;
    this.form.get('file').updateValueAndValidity();
  }

  fileValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        !!this.uploadedFile &&
        !this.allowedFileTypes.includes(this.uploadedFile?.type)
      ) {
        return { fileType: { value: this.uploadedFile?.type } };
      } else if (this.uploadedFile?.size > this.maximumFileSizeInBytes) {
        return { fileSize: { value: this.uploadedFile?.size } };
      } else {
        return null;
      }
    };
  }

  isRequired(controlName: string): boolean {
    return this.customRequiredFields.find(field => field === controlName) !== undefined;
  }

  resetForm(): void {
    this.resetFile();
    this.initForm();
  }
}
