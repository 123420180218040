<div class="newsletter">
  <div class="newsletter__title">
    <div class="title">Hírlevél feliratkozás</div>
  </div>
  <div class="newsletter__form">
    <form
      id="newsletter-form"
      [formGroup]="newsletterForm"
      (ngSubmit)="newsSubscription()"
    >

      <label>Neve <span class="important">*</span>
        <input
          type="text"
          class="input input--borderless"
          name="name"
          formControlName="name"
          required
        />
      </label>
      <label>E-mail címe <span class="important">*</span>
        <input
          type="text"
          class="input input--borderless"
          name="email"
          formControlName="email"
          required
        />
      </label>
     <!-- <div class="custom-control custom-switch switch-control">
        <input
          type="checkbox"
          class="custom-control-input switch-input"
          id="privacySwitch"
          formControlName="privacy"
          required
        />
        <label class="custom-control-label switch-label" for="privacySwitch">
          <a [routerLink]="['/1']">Adatvédelmi nyilatkozat</a> elfogadása
        </label>
      </div>-->
      <div class="custom-control custom-switch switch-control">

        <label>
          <input
            type="checkbox"
            class="custom-control-input switch-input"
            formControlName="privacy"
            required
          />
          <span class="custom-control-label switch-label">
            <a [routerLink]="['/1']">Adatvédelmi nyilatkozat</a> elfogadása
          </span>
        </label>
      </div>
      <div
        class="newsletter__errors"
        *ngIf="!newsletterForm.valid && !newsletterForm.pristine"
      >
        <ul class="newsletter__error-list">
          <li
            class="newsletter__error"
            *ngIf="
              newsletterForm.controls.name.errors?.required &&
              newsletterForm.controls.name.touched
            "
          >
            Név megadása kötelező
          </li>
          <li
            class="newsletter__error"
            *ngIf="
              newsletterForm.controls.email.errors?.required &&
              newsletterForm.controls.email.touched
            "
          >
            Email megadása kötelező
          </li>
          <li
            class="newsletter__error"
            *ngIf="
              !newsletterForm.controls.email.errors?.required &&
              newsletterForm.controls.email.errors?.email &&
              newsletterForm.controls.email.touched
            "
          >
            Email nem megfelelő
          </li>
          <li
            class="newsletter__error"
            *ngIf="
              newsletterForm.controls.privacy.errors?.required &&
              newsletterForm.controls.privacy.touched
            "
          >
            Adatvédelmi nyilatkozat elfogadása kötelező
          </li>
        </ul>
      </div>
      <button
        type="submit"
        [disabled]="!newsletterForm.valid"
        form="newsletter-form"
        class="button"
        value="Submit"
      >
        Feliratkozom
      </button>
    </form>
  </div>
</div>
