import { Component, OnInit, SecurityContext, ViewEncapsulation } from '@angular/core';
import { Footer } from 'src/app/models/footer';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],

})
export class FooterComponent implements OnInit {
  footerData: Footer;

  constructor(
    private mainService: MainService,
  ) { }

  ngOnInit(): void {
    this.mainService.getFooter().subscribe((data: Footer) => {
      this.footerData = data;   
    });
  }

}
