<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<app-main>
  <ng-container>
    <div class="title narrow">Bejelentő űrlapok</div>
    <div class="case__text">
      <p>Elérhető űrlapok:</p>
      <ul>
        <li><a routerLink="/etkezes-elvitel-kerelem">Kérelem hátrányos helyzetű gyermek intézményi étkezésének elviteléhez</a></li>
        <li><a routerLink="/zajbelento-urlap">Zajbejelentő űrlap</a></li>
        <li><a routerLink="/katyu-bejelento-urlap">Kátyú bejelentő űrlap</a></li>
        <li><a routerLink="/allatvedelmi-bejelento-urlap">Állatvédelmi bejelentő űrlap</a></li>
        <li><a routerLink="/mezogazdasagi-haszonallatok-bejelento-urlap">Mezőgazdasági haszonállatok bejelentő űrlap</a></li>
        <li><a routerLink="/ebosszeiras-bejelento-urlap">Ebösszeírás bejelentő űrlap</a></li>
      </ul>
    </div>
  </ng-container>
  <ng-container aside>
    <app-sidebar-article></app-sidebar-article>
  </ng-container>
</app-main>
