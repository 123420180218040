<div class="main container">
  <main class="main__main">
    <ng-content></ng-content>
  </main>
  <aside class="main__aside">
    <ng-content select="[aside]"></ng-content>
  </aside>
  <div class="main__wide">
    <ng-content select="[wide]"></ng-content>
  </div>
</div>
