import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {

  constructor(
    public mainService: MainService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap
      .pipe(
        switchMap((params: ParamMap) => {
          const urlString = this.route.snapshot.url.join('/');
          return this.mainService.getPageNotFound('/' + urlString, params);
        })
      )
      .subscribe(
        (data: {slug:string}) => {
          this.router.navigateByUrl(data.slug);
        },
        (error) => {
          this.router.navigateByUrl('/');
          this.snackbar.open(
            'Az oldal nem található.',
            null,
            {
              duration: 5000,
              verticalPosition: 'bottom',
              horizontalPosition: 'right',
            }
          );
        }
      );
  }
}
