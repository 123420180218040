import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { GalleryDialogComponent } from 'src/app/components/dialogs/gallery-dialog/gallery-dialog.component';
import { Gallery } from 'src/app/models/gallery';
import { GalleryList } from 'src/app/models/gallery-list';
import { DialogService } from 'src/app/services/dialog.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
  galleries: GalleryList;
  query;
  slug: string;

  constructor(
    public mainService: MainService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.query = this.route.snapshot.queryParamMap;
          this.slug = '/' + params.get('slug');
          return this.mainService.getGalleryList(
            this.slug,
            this.query
          );
        })
      )
      .subscribe((data) => {
        this.galleries = data;
      });
  }

  openGallery(gallery: Gallery) {
    this.dialogService.openModalWithParams(GalleryDialogComponent, gallery);
  }

  navToPage(ev) {
    let params = Object.assign({}, this.query.params);
    params.currentPage = ev;
    this.mainService
      .getGalleryList(this.slug, { params: params })
      .subscribe((data) => {
        this.galleries = data;
      });

    const urlString = this.route.snapshot.url.join('/');
    this.router.navigate([urlString], { queryParams: params }); // to change url
  }
}
