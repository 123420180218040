import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Breadcrumb } from 'src/app/models/breadcrumb';
import {
  CalendarEvent,
  CalendarEventCategories,
} from 'src/app/models/calendar-event';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit {
  eventCategories: CalendarEventCategories[];
  selectedCategories: number[];
  eventsOfDate: CalendarEvent[];
  breadcrumb: Breadcrumb[];

  categoryForm = this.fb.group({ categories: this.fb.array([]) });

  get categories() {
    return this.categoryForm.get('categories') as FormArray;
  }

  constructor(
    private mainService: MainService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.mainService.getBreadcrumb(this.router.url).subscribe((data) => {
      this.breadcrumb = data;
    });
    this.mainService.getCalendarEventCategories().subscribe((data) => {
      this.eventCategories = data;

      for (const category of this.eventCategories) {
        this.categories.push(this.fb.control(true));
      }
    });
    this.mainService
      .getCalendarEventsRecommendations()
      .subscribe((data: CalendarEvent[]) => {
        this.eventsOfDate = data;
      });
    this.categories.valueChanges.subscribe((value) => {
      this.selectedCategories = [];
      this.selectedCategories = value.reduce((categoryIds, checked, i) => {
        if (checked) {
          categoryIds.push(this.eventCategories[i].id);
        }
        return categoryIds;
      }, []);
    });
  }

  getDescription(event: CalendarEvent): string {
    if (!event.description) return '';
    if (event.description.length < 176) {
      return event.description;
    }
    return event.descriptionFullView
      ? event.description
      : event.description.substring(0, 172) + '...';
  }

  getEventsOfDay(date: Date, eventlist: HTMLElement) {
    let params: HttpParams = new HttpParams();
    params = params.set('year', '' + date.getFullYear());
    params = params.set('month', '' + (date.getMonth() + 1));
    params = params.set('date', '' + date.getDate());
    if (!!this.selectedCategories && !!this.selectedCategories.length) {
      params = params.set('categories', '' + this.selectedCategories);
    }

    this.mainService
      .getCalendarEvents(params)
      .subscribe((data: CalendarEvent[]) => {
        this.eventsOfDate = data;
        if (this.eventsOfDate.length > 0) {
          eventlist.scrollIntoView({behavior: 'smooth'});
        }
      });
  }
}
