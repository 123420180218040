<div class="mayor" *ngIf="!!mayor">
  <img
    [src]="mainService.getSmallestImageUrl(mayor.image)"
    [alt]="mayor.image.alternativeText"
    class="mayor__portrait"
  />
  <div class="mayor__top"></div>
  <div class="mayor__bottom">
    <div class="mayor__quotation-mark"></div>
    <div class="mayor__text" [innerHTML]="mayor.text"></div>
  </div>
</div>
