<app-breadcrumb [breadcrumb]="articles?.breadcrumb"></app-breadcrumb>
<app-main>
  <ng-container>
    <h1 class="list__title">{{ articles?.title }}</h1>
    <div class="list__container">
      <div class="list__item" *ngFor="let article of articleList">
        <app-preview
          [preview]="
            mainService.transformArticleToPreview(article, articleTypes.OTHER)
          "
          [wide]="true"
        ></app-preview>
      </div>
      <!-- <div class="pagination"></div> -->
    </div>
  </ng-container>

  <app-pager [pager]="articles?.data.pagination" (toPage)="navToPage($event)"></app-pager>

  <ng-container aside>
    <app-sidebar-article></app-sidebar-article>
  </ng-container>
</app-main>
