<footer class="footer">
  <div class="container footer__container">
    <section class="footer__column">
      <div class="footer__column__title">Kapcsolat</div>
      <div class="footer__column__text footer__column__text--address" [innerHtml]="footerData?.contact"></div>
      <a href="#" target="_blank" class="footer__column__link">Útvonaltervezés</a>
      <a [routerLink]="footerData?.privacy_policy_slug" class="footer__column__link">Adatvédelem</a>
      <a [routerLink]="footerData?.impressum_slug" class="footer__column__link">Impresszum</a>
      <a [routerLink]="footerData?.accessibility_policy" class="footer__column__link">Akadálymentesítési nyilatkozat</a>
    </section>

    <section class="footer__column">
      <div class="footer__column__title">Ügyfélfogadási rend</div>
      <div class="footer__column__text" [innerHtml]="footerData?.office_hours"></div>
    </section>

    <section class="footer__column">
      <div class="footer__column__title">Városháza</div>
      <a [routerLink]="footerData?.municipality_slug" class="footer__column__link">Önkormányzat</a>
      <a [routerLink]="footerData?.mayors_office_slug" class="footer__column__link">Polgármesteri hivatal</a>
      <a [routerLink]="footerData?.parliamentarian_slug" class="footer__column__link">Országgyűlési képviselő</a>
    </section>
  </div>
</footer>

