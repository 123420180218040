import { Component, OnInit } from '@angular/core';
import {Breadcrumb} from '../../models/breadcrumb';
import {MainService} from '../../services/main.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss'],
})
export class ReportFormComponent implements OnInit {
  breadcrumb: Breadcrumb[];
  constructor(
    private mainService: MainService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.mainService.getBreadcrumb(this.router.url).subscribe((data) => {
      this.breadcrumb = data;
    });
  }
}
