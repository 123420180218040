<div class="info" *ngIf="!closed">
  <div class="info__content">
    <img [src]="highlightedMessage?.image.url" [alt]="highlightedMessage?.image.alternativeText" class="info__icon">
    <a [routerLink]="highlightedMessage?.highlighted_message_static_page_slug">
      <p class="info__text">{{highlightedMessage?.message}}</p>
    </a>
  </div>
  <button class="info__close">
    <img src="assets/icons/info-close.svg" alt="Bezárás" (click)="closeInfo()">
  </button>
</div>
