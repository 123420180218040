import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Calendar, EventSourceInput } from '@fullcalendar/core';
import huLocale from '@fullcalendar/core/locales/hu';
// import momentPlugin from '@fullcalendar/moment';
import {
  CalendarOptions,
  EventInput,
  FullCalendarComponent,
} from '@fullcalendar/angular';
import { MainService } from 'src/app/services/main.service';
import { HttpParams } from '@angular/common/http';
import { CalendarEvent } from 'src/app/models/calendar-event';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class CalendarComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() categories: string[];
  @Input() date: Date = new Date();
  @Input() pageMode = false;
  @Output() dateClicked = new EventEmitter();

  calendarApi: Calendar;
  prevShownMonth: string;

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  eventList: EventInput[] = [];
  eventDayNumbers: number[] = [];
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    selectable: true,
    locale: huLocale,
    contentHeight: 'auto',
    headerToolbar: {
      start: 'prev',
      center: 'title',
      end: 'next',
    },
    now: this.onCalendarChange.bind(this), // date change event
    dateClick: this.onDateClick.bind(this), // day click event
    fixedWeekCount: false,
    showNonCurrentDates: false,
    // class for days
    dayCellClassNames: (arg) => {
      return this.eventDayNumbers.includes(+arg.dayNumberText)
        ? ['event-day']
        : [''];
    },
    events: this.eventList,
  };

  constructor(private mainService: MainService) {}

  ngOnInit(): void {
    this.getEventsForMonth(this.date);
  }

  ngOnChanges() {
    // rerender on input changes
    if (this.calendarApi) {
      this.getEventsForMonth(this.calendarApi.getDate());
    }
  }

  ngAfterViewChecked() {
      const calendarComponent = document.querySelectorAll('.calendar-container');
      calendarComponent.forEach((i) => {
        const calendarComponentRoot = i && i.shadowRoot;
        const prev = calendarComponentRoot.querySelector('.fc-prev-button');
        const next = calendarComponentRoot.querySelector('.fc-next-button');
        prev.setAttribute('aria-label', 'Előző');
        next.setAttribute('aria-label', 'Következő');
      });
  }

  getEventsForMonth(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth();

    let params: HttpParams = new HttpParams();
    params = params.set('year', '' + year);
    params = params.set('month', '' + (month + 1));
    if (!!this.categories && !!this.categories.length) {
      params = params.set('categories', '' + this.categories);
    }

    this.prevShownMonth = year + '-' + month;

    this.mainService
      .getCalendarEvents(params)
      .subscribe((data: CalendarEvent[]) => {
        this.eventList = [];
        this.eventDayNumbers = [];

        for (const event of data) {
          const endPlusOne = new Date(event.to_date);
          endPlusOne.setDate(endPlusOne.getDate() + 1);
          this.eventList.push({
            id: '' + event.id,
            title: event.title,
            start: event.from_date,
            end:
              endPlusOne.getFullYear() +
              '-' +
              ('0' + (endPlusOne.getMonth() + 1)).slice(-2) +
              '-' +
              ('0' + endPlusOne.getDate()).slice(-2),
            description: event.description,
            display: 'none',
          });
          for (
            let i = new Date(event.first_date), end = new Date(event.last_date);
            i <= end;
            i.setDate(i.getDate() + 1)
          ) {
            if (i.getMonth() === month) {
              this.eventDayNumbers.push(i.getDate());
            }
          }
        }

        this.calendarOptions.events = this.eventList;

        if (!this.calendarApi) {
          this.calendarApi = this.calendarComponent.getApi();
        }

        this.calendarApi?.destroy();
        this.calendarApi?.render();
      });
  }

  onCalendarChange() {
    if (this.calendarApi) {
      const changedMonth =
        this.calendarApi.getDate().getFullYear() +
        '-' +
        this.calendarApi.getDate().getMonth();
      if (this.prevShownMonth !== changedMonth) {
        this.getEventsForMonth(this.calendarApi.getDate());
      }
    }
  }

  onDateClick(a) {
    this.dateClicked.emit(a.date);
  }
}
