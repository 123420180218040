import {
  Component,
  forwardRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export interface ReCaptchaConfig {
  theme?: 'dark' | 'light';
  type?: 'audio' | 'image';
  size?: 'compact' | 'normal';
  tabindex?: number;
}

declare const grecaptcha: any;

declare global {
  interface Window {
    grecaptcha: any;
    reCaptchaLoad: () => void;
  }
}

@Component({
  selector: 'app-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecaptchaComponent),
      multi: true,
    },
  ],
})
export class RecaptchaComponent implements OnInit, ControlValueAccessor {
  @Input() key: string;
  @Input() config: ReCaptchaConfig = {};
  @Input() lang: string;
  @ViewChild('recaptcha') element;

  token: string;

  private _onChange: (value: string) => void;
  private _onTouched: (value: string) => void;

  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {
    this.registerReCaptchaCallback();
    this.addScript();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  // Define what should happen in this component, if something changes outside
  writeValue(token: string) {
    this.token = token;
  }

  // setup reCaptcha bind callbacks
  registerReCaptchaCallback() {
    window.reCaptchaLoad = () => {
      const config = {
        ...this.config,
        sitekey: this.key,
        callback: this.onSuccess.bind(this),
        'expired-callback': this.onExpired.bind(this),
      };
      this.render(this.element.nativeElement, config);
    };
  }

  // load reCaptcha api.js from google (append to the body)
  addScript() {
    let script = document.createElement('script');
    const lang = this.lang ? '&hl=' + this.lang : '';
    script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=explicit${lang}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  // render the reCaptcha widget
  private render(element: HTMLElement, config): number {
    return grecaptcha.render(element, config);
  }

  // Handle what should happen on the outside, if something changes on the inside
  // formControl is invalid if the onExpired function is called
  onExpired() {
    // ngZone brings it back to angular context (change detection)
    this.ngZone.run(() => {
      this._onChange('');
    });
  }

  // formControl is valid if we get the token from the onSuccess function
  onSuccess(token: string) {
    // ngZone brings it back to angular context (change detection)
    this.ngZone.run(() => {
      this._onChange(token);
    });
  }
}
