import { Component, OnInit } from '@angular/core';
import {Breadcrumb} from '../../models/breadcrumb';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MainService} from '../../services/main.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-dog-census-form',
  templateUrl: './dog-census-form.component.html',
  styleUrls: ['./dog-census-form.component.scss']
})
export class DogCensusFormComponent implements OnInit {
  private FORM_CATEGORY = 'DOG_CENSUS';

  breadcrumb: Breadcrumb[];

  form: FormGroup;

  siteKey = environment.siteKey;

  constructor(
    private mainService: MainService,
    private snackbar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.mainService.getBreadcrumb(this.router.url).subscribe((data) => {
      this.breadcrumb = data;
    });

    this.initForm();
  }

  initForm(): void {
    this.form = new FormGroup({
      owner_name: new FormControl('', Validators.required),
      owner_address: new FormControl('', Validators.required),
      carrier_name: new FormControl('', Validators.required),
      carrier_address: new FormControl('', Validators.required),
      carrier_phone_number: new FormControl('', Validators.required),
      carrier_email: new FormControl('', Validators.required),
      dog_breed: new FormControl('', Validators.required),
      dog_sex: new FormControl('', Validators.required),
      dog_color: new FormControl('', Validators.required),
      dog_birth_date: new FormControl('', Validators.required),
      dog_name: new FormControl('', Validators.required),
      dog_address: new FormControl('', Validators.required),
      chip_serial_number: new FormControl(''),
      chip_implantation_date: new FormControl(''),
      chip_implantation_doctor_name: new FormControl(''),
      chip_implantation_doctor_id: new FormControl(''),
      sterilization_date: new FormControl(''),
      sterilization_doctor_name: new FormControl(''),
      sterilization_doctor_id: new FormControl(''),
      dog_passport_number: new FormControl(''),
      dog_passport_date: new FormControl(''),
      dog_passport_doctor_name: new FormControl(''),
      dog_passport_doctor_id: new FormControl(''),
      vaccination_book_id: new FormControl('', Validators.required),
      vaccination_book_doctor_name: new FormControl('', Validators.required),
      vaccination_book_doctor_id: new FormControl('', Validators.required),
      last_vaccination_date: new FormControl('', Validators.required),
      last_vaccination_name_and_id: new FormControl('', Validators.required),
      last_vaccination_doctor_name: new FormControl('', Validators.required),
      last_vaccination_doctor_id: new FormControl('', Validators.required),
      rabies_status: new FormControl(''),
      rabies_observation_start_date: new FormControl(''),
      rabies_observation_period: new FormControl(''),
      dog_danger_status: new FormControl(''),
      dog_danger_status_date: new FormControl(''),
      alerting_name: new FormControl(''),
      alerting_address: new FormControl(''),
      alerting_phone_number: new FormControl(''),
      alerting_data_consent: new FormControl(''),
      recaptcha: new FormControl('', Validators.required),
    });
  }

  isInvalid(controlName: string): boolean {
    const control = this.form.get(controlName);
    return control.invalid && (control.dirty || control.touched);
  }

  onSubmit(): void {
    const formData = new FormData();
    for (const key in this.form.getRawValue()) {
      let value = this.form.get(key).value;
      if ([
        'dog_birth_date',
        'chip_implantation_date',
        'sterilization_date',
        'dog_passport_date',
        'last_vaccination_date',
        'rabies_observation_start_date',
        'dog_danger_status_date',
      ].includes(key)) {
        value = this.getFormattedDate(value);
      }

      formData.append(key, value);
    }
    formData.set('formCategory', this.FORM_CATEGORY);

    if (true || this.form.valid) {
      this.mainService.postReportForm(formData).subscribe(
        (resp) => {
          this.snackbar.open('Bejelentés elküldve.', null, {
            duration: 10000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
          });
        },
        (error) => {
          this.snackbar.open('Bejentés beküldése sikertelen.', 'Rendben', {
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
          });
        }
      );
    }
  }

  alertingDataConsentIsRequired(): boolean {
    for (const key in this.form.getRawValue()) {
      if (
        ['alerting_name', 'alerting_address', 'alerting_phone_number'].find(item => item === key) !== undefined
        && this.form.get(key).value !== ''
      ) {
        return this.form.get('alerting_data_consent').value !== true;
      }
    }
    return false;
  }

  resetForm(): void {
    this.initForm();
  }

  getFormattedDate(d: Date): string {
    if (!d) {
      return '';
    }

    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('.') + '.';
  }
}
