import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
})
export class NewsletterComponent implements OnInit {
  newsletterForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    privacy: new FormControl('', Validators.required),
  });

  constructor(
    private mainService: MainService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  newsSubscription() {
    if (this.newsletterForm.valid) {
      const payload = {
        email: this.newsletterForm.get('email').value,
        username: this.newsletterForm.get('name').value,
      };

      this.mainService.postNewsletterSubscription(payload).subscribe(
        (resp) => {
          this.snackbar.open('Sikereses hírlevél feliratkozás.', null, {
            duration: 10000,
            verticalPosition: 'bottom',
            horizontalPosition: 'right',
          });
        },
        (error) => {
          if (error.status === 400) {
            if (error.error === 'Please provide a valid email address.') {
              this.newsletterForm.get('email').setErrors({ email: true });
            } else if (error.error.includes('already a list member')) {
              this.snackbar.open('Meglévő hírlevél feliratkozás.', null, {
                duration: 10000,
                verticalPosition: 'bottom',
                horizontalPosition: 'right',
              });
            }
          }
        }
      );
    }
  }
}
