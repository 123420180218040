import { Component, OnInit } from '@angular/core';
import {Breadcrumb} from '../../models/breadcrumb';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {MainService} from '../../services/main.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Component({
  selector: 'app-catering-form',
  templateUrl: './catering-form.component.html',
  styleUrls: ['./catering-form.component.scss']
})
export class CateringFormComponent implements OnInit {
  cateringStartDateMin: Date = new Date();
  cateringEndDateMin: Date = new Date();
  disableCateringEndDate = true;
  invalidCateringEndDate = false;

  private FORM_CATEGORY = 'CATERING';

  breadcrumb: Breadcrumb[];

  form: FormGroup;

  siteKey = environment.siteKey;

  constructor(
    private mainService: MainService,
    private snackbar: MatSnackBar,
    private router: Router
  ) {
    const today = new Date();
    if (today.getHours() < 9) {
      this.cateringStartDateMin.setDate(today.getDate() + 1);
    } else {
      this.cateringStartDateMin.setDate(today.getDate() + 2);
    }
  }

  ngOnInit(): void {
    this.mainService.getBreadcrumb(this.router.url).subscribe((data) => {
      this.breadcrumb = data;
    });

    this.initForm();
  }


  initForm(): void {
    this.form = new FormGroup({
      kid_full_name: new FormControl('', Validators.required),
      school_name: new FormControl('', Validators.required),
      class_name: new FormControl('', Validators.required),
      catering_start_date: new FormControl('', Validators.required),
      catering_end_date: new FormControl('', Validators.required),
      catering_type: new FormControl('', Validators.required),
      recaptcha: new FormControl('', Validators.required),
    });
  }

  onSubmit(): void {
    this.invalidCateringEndDate = false;
    const formData = new FormData();
    for (const key in this.form.getRawValue()) {
      let value = this.form.get(key).value;
      if (['catering_start_date', 'catering_end_date'].includes(key)) {
        value = this.getFormattedDate(value);
      }

      formData.append(key, value);
    }
    formData.set('formCategory', this.FORM_CATEGORY);

    if (true || this.form.valid) {

      if (formData.get('catering_start_date') > formData.get('catering_end_date')) {
        this.invalidCateringEndDate = true;
        this.snackbar.open('Az étkezés elvitelének vége hibásan lett megadva.', 'Rendben', {
          verticalPosition: 'bottom',
          horizontalPosition: 'center',
        });
        return;
      } else {
        this.mainService.postReportForm(formData).subscribe(
          (resp) => {
            this.snackbar.open('Kérelem elküldve.', null, {
              duration: 10000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
          },
          (error) => {
            this.snackbar.open('Kérelem beküldése sikertelen.', 'Rendben', {
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
            });
          }
        );
      }
    }
  }

  isInvalid(controlName: string): boolean {
    const control = this.form.get(controlName);
    return control.invalid && (control.dirty || control.touched);
  }

  resetForm(): void {
    this.initForm();
  }

  cateringStartDateChanged(value: any): void {
    this.disableCateringEndDate = false;
    this.cateringEndDateMin = new Date(value);
  }

  getFormattedDate(d: Date): string {
    if (!d) {
      return '';
    }

    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('.') + '.';
  }
}
