import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { SliderOptions, SliderTheme } from 'src/app/models/slider-options';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class SliderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() options: SliderOptions;
  @ViewChild('previews') sliderTrack: ElementRef;
  themes = SliderTheme;
  page = 0;
  transitionTime = 1; // sec
  minPage: number;
  maxPage: number;
  hasFake: boolean;
  inView: number;
  screenType: string;
  sliderTransitionTime: string;
  intervalId: any;

  constructor(private responsiveService: ResponsiveService) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.options) {
      this.setSliderInView();
      this.hasFake = this.isCenterFaked();
      if (this.options.itemCount < this.inView) {
        this.options.isAuto = false;
        this.options.isInfinite = false;
      }
      this.setMinMax();
      this.setAutoPlay();
    }
  }

  setAutoPlay() {
    if (this.options.isAuto) {
      this.intervalId = setInterval(() => {
        this.nextSlide();
      }, 2000);
    }
  }

  destroyAutoPlay() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  prevSlide() {
    if (this.sliderTransitionTime === '0')
      this.sliderTransitionTime = `${this.transitionTime}s`;
    if (this.page > this.minPage) this.page--;
    if (this.options?.isInfinite && this.page === this.minPage) {
      setTimeout(() => {
        this.sliderTransitionTime = '0';
        this.page = this.options.itemCount;
      }, this.transitionTime * 1000);
    }
  }
  nextSlide() {
    if (this.sliderTransitionTime === '0')
      this.sliderTransitionTime = `${this.transitionTime}s`;
    if (this.page < this.maxPage) this.page++;
    if (this.options?.isInfinite && this.page === this.maxPage) {
      setTimeout(() => {
        this.sliderTransitionTime = '0';
        this.page = this.options.itemCount * 2 - this.inView;
      }, this.transitionTime * 1050);
    }
  }

  isCenterFaked(): boolean {
    let isFake = false;
    if (this.options.isCentered) {
      if (this.options.itemCount > this.inView) {
        isFake = this.options.itemCount % 2 !== this.inView % 2;
      }
    }
    return isFake;
  }

  setMinMax() {
    if (this.options.isCentered && this.options.itemCount > this.inView) {
      this.maxPage = ~~((this.options.itemCount - this.inView) / 2);
      this.minPage = ~~((this.options.itemCount - this.inView) / 2) * -1;
      if (this.hasFake && this.inView < this.options.itemCount) this.minPage--;
    } else if (this.options.isInfinite) {
      this.maxPage = this.options.itemCount * 3 - this.inView;
      this.minPage = 0;
      this.page = this.options.itemCount;
    } else {
      this.maxPage = this.options.itemCount - this.inView;
      this.minPage = 0;
    }
    if (this.page > this.maxPage) this.page = this.maxPage;
    if (this.page < this.minPage) this.page = this.minPage;
  }

  setSliderInView() {
    if (this.responsiveService.screenWidth === this.responsiveService.MOBILE) {
      this.inView = this.options.itemInView.xs;
    }
    if (this.responsiveService.screenWidth === this.responsiveService.TABLET) {
      this.inView = this.options.itemInView.sm || this.options.itemInView.xs;
    }
    if (
      this.responsiveService.screenWidth ===
      this.responsiveService.DESKTOP_SMALL
    ) {
      this.inView =
        this.options.itemInView.md ||
        this.options.itemInView.sm ||
        this.options.itemInView.xs;
    } else if (
      this.responsiveService.screenWidth === this.responsiveService.DESKTOP
    ) {
      if (this.page > this.maxPage) this.page = this.maxPage;
      this.inView =
        this.options.itemInView.lg ||
        this.options.itemInView.md ||
        this.options.itemInView.sm ||
        this.options.itemInView.xs;
    }
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.destroyAutoPlay();
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.setAutoPlay();
  }

  @HostListener('window:orientationchange', ['$event'])
  @HostListener('window:resize', ['$event'])
  onResize($event: any) {
    if (this.screenType !== this.responsiveService.checkWidth()) {
      this.ngOnChanges();
    }
  }

  ngOnDestroy(): void {
    this.destroyAutoPlay();
  }
}
