import { HttpParams } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Header } from 'src/app/models/header';
import { Menu } from 'src/app/models/menu';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isOpen = false;
  today = new Date();
  accessibilityView = false;
  searchToggle = false;

  headerData: Header;
  nameday: string;
  searchQuery: string;

  navbarfixed: boolean = false;

  constructor(public router: Router, private mainService: MainService) {
    router.events
      .pipe(
        filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd)
      )
      .subscribe((e: NavigationEnd) => {
        this.closeMenu(null); // close all
        this.setActiveMenu();
      });
  }

  ngOnInit(): void {
    this.mainService.getHeader().subscribe((data: Header) => {
      this.headerData = data;
      this.setActiveMenu();
    });

    this.mainService.getNamedays().subscribe((data) => {
      const namedays =
        data[this.today.getMonth() + 1][this.today.getDate()].main;
      this.nameday = '';
      namedays.forEach((nameday, index) => {
        this.nameday += index > 0 ? ', ' + nameday : nameday;
      });
    });

    this.mainService.accessibilityView$.subscribe((data: boolean) => {
      this.accessibilityView = data;
    });
  }

  showSearch() {
    this.searchToggle = !this.searchToggle;
  }

  setAccessibilityView() {
    this.mainService.toggleAccessibilityView();
  }

  onSearch() {
    if (!!this.searchQuery) {
      this.router.navigate(['/search/'], {
        queryParams: { keyword: this.searchQuery },
      });
    }
  }

  setActiveMenu() {
    const activeLink = this.router.url.substring(1);
    this.headerData?.menu.map((menu) => {
      menu.isActive = '' + menu.slug === activeLink;
      menu.sub_menu?.map((subMenu) => {
        subMenu.isActive = '' + subMenu.slug === activeLink;
        if (subMenu.isActive) {
          menu.isActive = true;
        }
        subMenu.sub_menu?.map((subMenuLvl2) => {
          subMenuLvl2.isActive = '' + subMenuLvl2.slug === activeLink;
          if (subMenuLvl2.isActive) {
            subMenu.isActive = true;
            menu.isActive = true;
          }
        });
      });
    });
  }

  onMenuClick(menuItem: Menu) {
    menuItem.isOpen ? this.closeMenu(menuItem) : this.openMenu(menuItem);
  }

  closeMenu(menuItem: Menu) {

    this.headerData?.menu.map((menu) => {
      menu.isOpen = false;
      menu.sub_menu?.map((submenu) => {
        submenu.isOpen = false;
        if (menuItem === submenu) menu.isOpen = true;
      });
    });
  }

  openMenu(menuItem: Menu) {
    this.headerData?.menu.map((menu) => {
      if (menu === menuItem) {
        menu.isOpen = true;
      } else {
        menu.isOpen = false;
      }
      menu.sub_menu?.map((submenu) => {
        if (submenu === menuItem) {
          menu.isOpen = true;
          submenu.isOpen = true;
        } else {
          submenu.isOpen = false;
        }
      });
    });
  }

  @HostListener('window:scroll',['$event']) onscroll() {
    if(window.scrollY > 73) {
      this.navbarfixed = true;
    }
    else {
      this.navbarfixed = false;
    }
  }
}
