<div class="phone">
  <div class="phone__text">Időpontkérés Hivatali Ügyfélfogadásra</div>
  <div class="phone__highlighted bottom">
    <a href="tel:+3612533300"> +36 1 253 3300 </a>
  </div>
  <a
    [routerLink]="['/static-pages/idopontkeres-uegyfelfogadasra']"
    routerLinkActive="router-link-active"
    class="phone__more"
  >
    Részletekért kattintson ide
  </a>
</div>
