import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-sidebar-article',
  templateUrl: './sidebar-article.component.html',
  styleUrls: ['./sidebar-article.component.scss'],
})
export class SidebarArticleComponent implements OnInit {
  constructor(private mainService: MainService) {}

  ngOnInit(): void {}

  calendarClicked(ev) {
    this.mainService.navigateToEventPage();
  }
}
