<div class="cookie" *ngIf="showCookie">
  <div class="container cookie__container">
    <div class="cookie__info">
      <h1 class="cookie__title">Oldalunk cookie-kat ("sütiket") használ</h1>
      <p class="cookie__text">
        Ez a weboldal a felhasználói élmény javítása, valamint a zavartalan
        működés biztosítása érdekében sütiket (cookie-kat) használ. A sütik
        kikapcsolása a böngésző beállításaiban lehetséges.
      </p>
      <p class="cookie__text">
        <a [routerLink]="" class="cookie__link"> Adatvédelmi irányelvek </a>
      </p>
    </div>
    <div class="cookie__actions">
      <button
        type="button"
        class="cookie__action cookie__action--heavy"
        (click)="acceptCookie(true)"
      >
        Elfogadom
      </button>
      <button
        type="button"
        class="cookie__action"
        (click)="acceptCookie(false)"
      >
        Visszautasítom
      </button>
    </div>
  </div>
</div>
