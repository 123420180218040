<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<app-main>
  <ng-container>
    <div class="title narrow">Mezőgazdasági haszonállatok bejelentő űrlap</div>
    <div class="report-form">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" id="report-form">
        <div class="title--sub--sub">Állatfajonként külön kérdőívet kell kitölteni!</div>
        <div class="title--sub">Tulajdonosra, állattartóra vonatkozó adatok</div>
        <label>
          <span>Az állat tulajdonosának neve <span class="required">*</span></span>
          <input
            type="text"
            name="owner_name"
            id="owner_name"
            formControlName="owner_name"
            required
            [ngClass]="{ error: isInvalid('owner_name') }"
          />
          <div class="error-text" *ngIf="isInvalid('owner_name')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label>
          <span>Az állat tulajdonosának címe <span class="required">*</span></span>
          <input
            type="text"
            name="owner_address"
            id="owner_address"
            formControlName="owner_address"
            required
            [ngClass]="{ error: isInvalid('owner_address') }"
          />
          <div class="error-text" *ngIf="isInvalid('owner_address')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label>
          <span>Az állat tulajdonosának telefonszáma <span class="required">*</span></span>
          <input
            type="text"
            name="owner_phone_number"
            id="owner_phone_number"
            formControlName="owner_phone_number"
            required
            [ngClass]="{ error: isInvalid('owner_phone_number') }"
          />
          <div class="error-text" *ngIf="isInvalid('owner_phone_number')">
            A mező kitöltése kötelező
          </div>
        </label>

        <div class="title--sub">A tartott állatokra vonatkozó általános adatok</div>
        <label class="half-width">
          <span>Az állatok faja <span class="required">*</span></span>
          <input
            type="text"
            name="dogs_breed"
            id="dogs_breed"
            formControlName="dogs_breed"
            required
            [ngClass]="{ error: isInvalid('dogs_breed') }"
          />
          <div class="error-text" *ngIf="isInvalid('dogs_breed')">
            A mező kitöltése kötelező
          </div>
        </label>
        <label class="half-width">
          <span>Az állatok darabszáma <span class="required">*</span></span>
          <input
            type="number"
            name="dogs_number"
            id="dogs_number"
            formControlName="dogs_number"
            min="1"
            required
            [ngClass]="{ error: isInvalid('dogs_number') }"
          />
          <div class="error-text" *ngIf="isInvalid('dogs_number')">
            A mező kitöltése kötelező
          </div>
        </label>

        <div class="report-form__captcha">
          <app-recaptcha
            [key]="siteKey"
            formControlName="recaptcha"
          ></app-recaptcha>
        </div>

        <div class="button-container">
          <button type="submit" form="report-form" [disabled]="!form.valid">
            Elküldés
          </button>
          <button type="button" (click)="resetForm()">Mégse</button>
        </div>
      </form>
    </div>
  </ng-container>
  <ng-container aside>
    <app-sidebar-article></app-sidebar-article>
  </ng-container>
</app-main>
