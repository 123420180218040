import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Cookie } from 'src/app/models/cookie';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss'],
})
export class CookieComponent implements OnInit {
  cookie: Cookie;
  showCookie: boolean;

  constructor(private mainService: MainService) {}

  ngOnInit(): void {
    this.cookie = this.mainService.getCookie();
    this.showCookie = !this.cookie || this.isAllRejected(this.cookie);
  }

  acceptCookie(accept: boolean) {
    this.showCookie = false;
    this.cookie = new Cookie(true, false, false, false);
    accept ? this.cookie.acceptAll() : this.cookie.rejectAll();
    this.mainService.cookieOption = this.cookie;
  }

  // returns true if only necessary cookies accepted (to ask again)
  isAllRejected(cookie: Cookie): boolean {
    return !(cookie.marketing || cookie.statistic || cookie.preferences);
  }
}
