import {Component, HostListener, OnInit} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Cookie } from './models/cookie';
import { LoadingMarkerService } from './services/loading-marker.service';
import { MainService } from './services/main.service';

declare var gtag;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // isLoading: boolean;
  navbarfixed: boolean = false;

  constructor(
    private mainService: MainService,
    private router: Router
  )

  // private loadingMarkerService: LoadingMarkerService
  {}

  ngOnInit(): void {
    let cookieSub;
    const navEndEvent$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd)
    );

    this.mainService.cookieOption$.subscribe((cookie: Cookie) => {
      // Google Analytics
      if (cookie?.statistic && !cookieSub && !!environment.measurementId) {
        // add script to header
        const script = document.createElement('script');
        script.async = true;
        script.src =
          'https://www.googletagmanager.com/gtag/js?id=' + environment.measurementId;
        document.head.prepend(script);

        // set up analytics for navigation
        cookieSub = navEndEvent$.subscribe((e: NavigationEnd) => {
          gtag('config', environment.measurementId, {
            page_path: e.urlAfterRedirects,
          });
        });
        // this.gtmService.addGtmToDom();
      } else if (!!cookieSub) {
        cookieSub.unsubscribe();
      }
    });
    // this.loadingMarkerService.loadingMarkerStateEmitter.subscribe(
    //   (isLoading) => (this.isLoading = isLoading)
    // );
    // this.loadingMarkerService.markerOff();
    // no need to unsubscribe here, since AppComponent is created only once
  }
  @HostListener('window:scroll',['$event']) onscroll() {
    if(window.scrollY > 73) {
      this.navbarfixed = true;
    }
    else {
      this.navbarfixed = false;
    }
  }

}
