import { Component, Input, OnInit } from '@angular/core';
import {NgbCarousel, NgbCarouselConfig, NgbSlideEvent, NgbSlideEventSource} from '@ng-bootstrap/ng-bootstrap';
import { ArticleCard } from 'src/app/models/article-card';
import { MainService } from 'src/app/services/main.service';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  @Input('pages') articles: ArticleCard[]; //TODO make carousel model if used for something else than article
  @Input('max-img-width') maxImgWidth: number;

  constructor(
    public mainService: MainService,
    config: NgbCarouselConfig) {
    config.interval = 5000;
  }

  ngOnInit(): void {
  }

}
