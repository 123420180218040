<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<app-main>
  <ng-container>
    <article>
      <div class="static__cover">
        <img src="assets/icons/thumbnail_cimer.jpg" alt="címer" />
      </div>
      <h1 class="static__title">Orvosi körzet kereső</h1>
      <p>
        Először válassza ki az egészségügyi szolgáltatást, majd írja a keresőbe
        a közterület nevét!<br />
        <span class="note">(Például: Mátka utca esetén "Mátka")</span>
      </p>
      <form [formGroup]="form" id="search-form">
        <div class="categories">
          <label>
            <mat-checkbox name="doctor" formControlName="categoryDoctor">
              Felnőtt háziorvos
            </mat-checkbox>
          </label>
          <label>
            <mat-checkbox
              name="doctor-child"
              formControlName="categoryDoctorChild"
            >
              Gyerek háziorvos
            </mat-checkbox>
          </label>
          <label>
            <mat-checkbox
              class="example-margin"
              name="dentist"
              formControlName="categoryDentist"
            >
              Felnőtt fogorvos
            </mat-checkbox>
          </label>
          <label>
            <mat-checkbox
              name="dentist-child"
              formControlName="categoryDentistChild"
            >
              Gyerek fogorvos
            </mat-checkbox>
          </label>
        </div>
        <label>
          <span class="label-input">Közterület neve</span>
          <input
            type="text"
            name="location"
            class="input"
            formControlName="location"
          />
        </label>
      </form>

      <p>
        {{ message }}
      </p>
      <div class="result">
        <span class="doctor_header" *ngIf="resultsAvaiable"> Közterület </span>
        <span class="doctor_header" *ngIf="resultsAvaiable"> Orvos </span>
        <span class="doctor_header" *ngIf="resultsAvaiable">
          Szolgáltatás
        </span>
        <ng-container *ngFor="let doctors of filteredDoctors$ | async as list">
          <div class="doctor_item location">
            <span class="doctor_label"> Közterület </span>
            <span class="doctor_value">
              {{ doctors.location }}
            </span>
          </div>
          <div class="doctor_item name">
            <span class="doctor_label"> Orvos </span>
            <span class="doctor_value">
              <a [href]="doctors.link" target="_blank">
                {{ doctors.doctor }}
              </a>
            </span>
          </div>
          <div class="doctor_item category">
            <span class="doctor_label"> Szolgáltatás </span>
            <span class="doctor_value">
              {{ mapDoctors(doctors.category) }}
            </span>
          </div>
        </ng-container>
      </div>
    </article>
  </ng-container>
  <ng-container aside>
    <app-sidebar-article></app-sidebar-article>
  </ng-container>
</app-main>
