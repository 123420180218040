import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Gallery } from 'src/app/models/gallery';
import { Preview } from 'src/app/models/preview';
import { DialogService } from 'src/app/services/dialog.service';
import { MainService } from 'src/app/services/main.service';
import { GalleryDialogComponent } from '../dialogs/gallery-dialog/gallery-dialog.component';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit {
  @Input() preview: Preview;
  @Input() wide: boolean = false;
  @Input() minimal: boolean = false;

  constructor(
    private router: Router,
    private mainService: MainService,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {}

  onNavigateToPage(ev, slug) {
    if (slug.includes('/uploads/')) {
      window.open(slug, '_blank');
      return;
    }
      this.router.navigateByUrl(slug);
  }

  onVideoClick(ev, slug) {
    this.router.navigateByUrl(slug);
  }

  onGalleryClick(ev, galleryId: string) {
    this.mainService.getGallery('/' + galleryId).subscribe((data) => {
      this.dialogService.openModalWithParams(GalleryDialogComponent, data);
    })
  }

  bypassHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html + '');
  }
}
