export class Cookie {
  necessary: boolean;
  statistic: boolean;
  preferences: boolean;
  marketing: boolean;

  constructor(
    necessary: boolean,
    statistic: boolean,
    preferences: boolean,
    marketing: boolean
  ) {}

  acceptAll() {
    this.necessary = true;
    this.statistic = true;
    this.preferences = true;
    this.marketing = true;
  }

  rejectAll() {
    this.necessary = true;
    this.statistic = false;
    this.preferences = false;
    this.marketing = false;
  }
}
