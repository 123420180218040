import { Component, Input, OnInit } from '@angular/core';
import { Banner } from 'src/app/models/banner';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  @Input() banner: Banner;

  constructor(public mainService: MainService) {}

  ngOnInit(): void {}
}
