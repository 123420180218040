import { Component, Input, OnInit } from '@angular/core';
import { HighlightedMessage } from 'src/app/models/highlighted-message';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {
  @Input() highlightedMessage: HighlightedMessage;

  closed = false;

  constructor(private mainService: MainService) {}

  ngOnInit(): void {
    this.closed = this.mainService.isInfoClosed();
  }

  closeInfo() {
    this.closed = true;
    this.mainService.closeInfo();
  }
}
