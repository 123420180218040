import { Gallery } from "./gallery";
import { ImageFile } from "./image-file";

export enum PreviewType {
  GALLERY = 'gallery',
  ARTICLE = 'article',
  STATIC = 'static_page',
  SEARCH = 'search_item'
}

export class Preview {
  date: Date;
  category: string;
  title: string;
  lead: string;
  thumbnailSrc: string;
  thumbnailAlt: string;
  slug: string;
  galleryId: string;
  videoSlug: string;
  sourceType: PreviewType;
  images: ImageFile[]; // if its a gallery. Refactor maybe?
}
