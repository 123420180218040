<app-breadcrumb [breadcrumb]="galleries?.breadcrumb"></app-breadcrumb>
<app-main>
  <ng-container>
    <h1 class="list__title">Galéria</h1>
    <h2 class="list__subtitle">{{ galleries?.title }}</h2>
    <div class="list__container">
      <div class="list__item" *ngFor="let gallery of galleries?.data.data">
        <div class="list__action" (click)="openGallery(gallery)">
          <app-preview
            [preview]="mainService.transformGalleryToPreview(gallery)"
          ></app-preview>
        </div>
      </div>
    </div>
  </ng-container>
  <app-pager [pager]="galleries?.data.pagination" (toPage)="navToPage($event)"></app-pager>
  <ng-container aside>
    <app-sidebar-article></app-sidebar-article>
  </ng-container>
</app-main>
