import { Component, OnInit } from '@angular/core';
import {Breadcrumb} from '../../models/breadcrumb';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {MainService} from '../../services/main.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Component({
  selector: 'app-agricultural-farm-animal-form',
  templateUrl: './agricultural-farm-animal-form.component.html',
  styleUrls: ['./agricultural-farm-animal-form.component.scss']
})
export class AgriculturalFarmAnimalFormComponent implements OnInit {
  private FORM_CATEGORY = 'AGRICULTURAL_FARM_ANIMAL';

  breadcrumb: Breadcrumb[];

  form: FormGroup;

  siteKey = environment.siteKey;

  constructor(
    private mainService: MainService,
    private snackbar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.mainService.getBreadcrumb(this.router.url).subscribe((data) => {
      this.breadcrumb = data;
    });

    this.initForm();
  }
  initForm(): void {
    this.form = new FormGroup({
      owner_name: new FormControl('', Validators.required),
      owner_address: new FormControl('', Validators.required),
      owner_phone_number: new FormControl('', Validators.required),
      dogs_breed: new FormControl('', Validators.required),
      dogs_number: new FormControl('', Validators.required),
      recaptcha: new FormControl('', Validators.required),
    });
  }

  isInvalid(controlName: string): boolean {
    const control = this.form.get(controlName);
    return control.invalid && (control.dirty || control.touched);
  }

  onSubmit(): void {
    const formData = new FormData();
    for (const key in this.form.getRawValue()) {
      formData.append(key, this.form.get(key).value);
    }
    formData.set('formCategory', this.FORM_CATEGORY);

    if (true || this.form.valid) {
      this.mainService.postReportForm(formData).subscribe(
        (resp) => {
          this.snackbar.open('Bejelentés elküldve.', null, {
            duration: 10000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
          });
        },
        (error) => {
          this.snackbar.open('Bejentés beküldése sikertelen.', 'Rendben', {
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
          });
        }
      );
    }
  }

  resetForm(): void {
    this.initForm();
  }
}
