import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noise-report-form',
  templateUrl: './noise-report-form.component.html',
  styleUrls: ['./noise-report-form.component.scss']
})
export class NoiseReportFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
