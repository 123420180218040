import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarHomeComponent } from './components/sidebar/sidebar-home/sidebar-home.component';
import { ArticleListComponent } from './pages/article/article-list/article-list.component';
import { ArticleShowComponent } from './pages/article/article-show/article-show.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppHttpInterceptor } from './services/http.interceptor';
import { registerLocaleData } from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import { StaticComponent } from './pages/static/static.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselComponent } from './components/carousel/carousel.component';
import { InfoComponent } from './components/info/info.component';
import { PreviewComponent } from './components/preview/preview.component';
import { MayorComponent } from './components/mayor/mayor.component';
import { PhoneComponent } from './components/phone/phone.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { HomeComponent } from './pages/home/home.component';
import { SliderComponent } from './components/slider/slider.component';
import { ReportFormComponent } from './pages/report-form/report-form.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!

import { CalendarComponent } from './components/calendar/calendar.component';
// import interactionPlugin from '@fullcalendar/interaction';
import { BannerComponent } from './components/banner/banner.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import { MainComponent } from './components/main/main.component';
import { SidebarArticleComponent } from './components/sidebar/sidebar-article/sidebar-article.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { ContentComponent } from './components/content/content.component';
import { PagerComponent } from './components/pager/pager.component';
import { GalleryDialogComponent } from './components/dialogs/gallery-dialog/gallery-dialog.component';
import { PhonebookComponent } from './pages/phonebook/phonebook.component';
import { EventComponent } from './pages/event/event.component';
import { ResultsComponent } from './pages/results/results.component';
import { CookieComponent } from './components/cookie/cookie.component';
import { BannerStaticComponent } from './components/banner-static/banner-static.component';
import { CaseComponent } from './pages/case/case.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { FindDoctorsComponent } from './pages/find-doctors/find-doctors.component';
import { DogCensusFormComponent } from './pages/dog-census-form/dog-census-form.component';
import { PotholeReportFormComponent } from './pages/pothole-report-form/pothole-report-form.component';
import { NoiseReportFormComponent } from './pages/noise-report-form/noise-report-form.component';
import { CommonReportFormComponent } from './components/common-report-form/common-report-form.component';
import { AnimalProtectionReportFormComponent } from './pages/animal-protection-report-form/animal-protection-report-form.component';
import { AgriculturalFarmAnimalFormComponent } from './pages/agricultural-farm-animal-form/agricultural-farm-animal-form.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatNativeDateModule} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CateringFormComponent } from './pages/catering-form/catering-form.component';

registerLocaleData(localeHu);

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
]);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarHomeComponent,
    ArticleListComponent,
    ArticleShowComponent,
    HomeComponent,
    StaticComponent,
    BreadcrumbComponent,
    CarouselComponent,
    InfoComponent,
    PreviewComponent,
    MayorComponent,
    PhoneComponent,
    NewsletterComponent,
    SliderComponent,
    ReportFormComponent,
    CalendarComponent,
    BannerComponent,
    RecaptchaComponent,
    MainComponent,
    SidebarArticleComponent,
    GalleryComponent,
    ContentComponent,
    PagerComponent,
    GalleryDialogComponent,
    PhonebookComponent,
    EventComponent,
    ResultsComponent,
    CookieComponent,
    BannerStaticComponent,
    CaseComponent,
    PageNotFoundComponent,
    FindDoctorsComponent,
    DogCensusFormComponent,
    PotholeReportFormComponent,
    NoiseReportFormComponent,
    CommonReportFormComponent,
    AnimalProtectionReportFormComponent,
    AgriculturalFarmAnimalFormComponent,
    CateringFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    FullCalendarModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCheckboxModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'hu-HU',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
