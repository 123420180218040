import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleListComponent } from './pages/article/article-list/article-list.component';
import { ArticleShowComponent } from './pages/article/article-show/article-show.component';
import { EventComponent } from './pages/event/event.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { HomeComponent } from './pages/home/home.component';
import { ReportFormComponent } from './pages/report-form/report-form.component';
import { StaticComponent } from './pages/static/static.component';
import { PhonebookComponent } from './pages/phonebook/phonebook.component';
import { ResultsComponent } from './pages/results/results.component';
import { CaseComponent } from './pages/case/case.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { FindDoctorsComponent } from './pages/find-doctors/find-doctors.component';
import {NoiseReportFormComponent} from './pages/noise-report-form/noise-report-form.component';
import {PotholeReportFormComponent} from './pages/pothole-report-form/pothole-report-form.component';
import {
  AnimalProtectionReportFormComponent
} from './pages/animal-protection-report-form/animal-protection-report-form.component';
import {AgriculturalFarmAnimalFormComponent} from './pages/agricultural-farm-animal-form/agricultural-farm-animal-form.component';
import {DogCensusFormComponent} from './pages/dog-census-form/dog-census-form.component';
import {CateringFormComponent} from './pages/catering-form/catering-form.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
  },
  {
    path: 'homepage',
    component: HomeComponent,
  },
  {
    path: 'report',
    component: ReportFormComponent,
  },
  {
    path: 'phonebook',
    component: PhonebookComponent,
  },
  {
    path: 'find-doctors',
    component: FindDoctorsComponent,
  },
  {
    path: 'calendar',
    component: EventComponent,
  },
  {
    path: 'results',
    component: ResultsComponent,
  },
  {
    path: 'case',
    component: CaseComponent,
  },
  {
    path: 'article-lists/:slug',
    component: ArticleListComponent,
  },
  {
    path: 'articles/:slug',
    component: ArticleShowComponent,
  },
  {
    path: 'gallery-lists/:slug',
    component: GalleryComponent,
  },
  {
    path: 'static-pages/:slug',
    component: StaticComponent,
  },
  {
    path: 'search',
    component: ResultsComponent,
  },
  {
    path: 'zajbelento-urlap',
    component: NoiseReportFormComponent,
  },
  {
    path: 'katyu-bejelento-urlap',
    component: PotholeReportFormComponent,
  },
  {
    path: 'allatvedelmi-bejelento-urlap',
    component: AnimalProtectionReportFormComponent,
  },
  {
    path: 'mezogazdasagi-haszonallatok-bejelento-urlap',
    component: AgriculturalFarmAnimalFormComponent,
  },
  {
    path: 'ebosszeiras-bejelento-urlap',
    component: DogCensusFormComponent,
  },
  {
    path: 'etkezes-elvitel-kerelem',
    component: CateringFormComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
