<div class="carousuel">
  <ngb-carousel data-interval="false">
    <ng-template ngbSlide *ngFor="let image of gallery.image">
      <div class="picsum-img-wrapper">
        <img [src]="image?.url" [alt]="image?.alternativeText" />
      </div>
    </ng-template>
  </ngb-carousel>
</div>
<div class="dialog__close"></div>
