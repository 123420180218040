<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<app-main>
  <ng-container>
    <div class="results-info">
      <h1 class="results-title">Találatok</h1>
      <div class="details text">Keresett kifejezés: “{{ query.params.keyword }}”</div>
      <div class="details match">
        Találatok összesen: {{ results?.pagination.total || 0 }} db
      </div>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="result-card" *ngFor="let result of results?.data">
      <div class="result-card__breadcrumb">
        <app-breadcrumb
          [breadcrumb]="result.breadcrumb"
          [lightMode]="true"
        ></app-breadcrumb>
      </div>

      <div class="result__item">
        <app-preview
          [preview]="mainService.transformSearchItemToPreview(result)"
          [wide]="true"
          [minimal]="true"
        ></app-preview>
      </div>
    </div>
    <app-pager
      [pager]="results?.pagination"
      (toPage)="navToPage($event)"
    ></app-pager>
  </ng-container>

  <ng-container aside>
    <app-sidebar-article></app-sidebar-article>
  </ng-container>
</app-main>
